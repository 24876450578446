import React, { useEffect, useState }from "react"

import styled from "@emotion/styled"
import { STYLED_VARIABLES } from "@cloudike/web_ui_components"

import { FilesUploadingWidget } from "../files-uploading/FilesUploadingWidget"
import { Notifications } from "../notifications"
import { getIsMobileMenuVisible } from "../selectors"

const NotifAndUploadWidget = () => {

  const isMenuVisible = getIsMobileMenuVisible()

  return (
    <NotifAndUploadWidgetBox bottom={ isMenuVisible ? '86px' : '0' }>
      <Notifications />

      <FilesUploadingWidget />
    </NotifAndUploadWidgetBox>
  )
}
const NotifAndUploadWidgetBox = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: end;
  z-index: 101;
  bottom: ${(props:{bottom: string}) => props.bottom };
  
  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: 100%;
  }

  @media screen and (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    z-index: 14;
  }
  
  @media screen and (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    padding: 0 0 24px 24px;
    bottom: 0;
  }
`

export default NotifAndUploadWidget
