import { STYLED_VARIABLES } from 'constants/styledVariables'

import { useState } from 'react'

import { MenuItem, MenuWithActionItems } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getUserDataSelector, getUserSettingsByPathSelector } from 'features/user/selectors'
import { useAppDispatch } from "store"
import { DialogModal } from 'ui/DialogModal'
import { createAndShareAlbumThunk, createNewAlbumWithItemsThunk } from 'features/albums/album/albumSlice'
import { useNavigate } from 'react-router-dom'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { appActions } from 'store/app'
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'

import { getContentTypesUploadSelector } from "../../common/selectors"
import { addSelectedToFavoritesItemsThunk, removeSelectedFromFavoritesItemsThunk } from "../../favorites/favoritesSlice"
import { openPreviewDuplicateThunk } from "../photo-preview-duplicate/photoPreviewDuplicateSlice"
import { USER_SETTINGS_PATHS } from "../../user/constants"
import { MobileToolbarCounter } from "../../common/mobile-toolbar-counter/MobileToolbarCounter"
import { FAMILY_ONBOARDING_CLASSES } from "../../../constants/familyOnboarding"

import {
  getTimelineFilterSelector,
  getTimelineItemsSelector,
  getTimelineSelectedItemsSelector,
  getTimelineTotalItemsCountSelector
} from './selectors'
import {
  copyTimelineItemsToFamilyThunk,
  copyTimelineItemsToPersonalCloudThunk,
  downloadTimelineItemsThunk,
  removeSelectedItemsThunk,
  timelineActions
} from './timelineSlice'
import { useUploadTimelineItemsUploadHandler } from "./useUploadFilesHandler"

interface TimelineToolbarProps {
  className?: string,
  type: SDK_TYPES
}

export const TimelineToolbar: React.FC<TimelineToolbarProps> = ({ className = '', type }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const userData = getUserDataSelector()
  const selectedItems = getTimelineSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  // const fileInput = useRef(null)
  const contentTypesUpload = getContentTypesUploadSelector()
  const isEnabledPhotoDuplicates = getUserSettingsByPathSelector(USER_SETTINGS_PATHS.PHOTOS_DUPLICATES).enabled
  const filter = getTimelineFilterSelector()

  const [removingConfirmationModalOpened, toggleRemovingConfirmationModal] = useState(false)

  const isSelectedItemsHaveOnlyFavorite = () => {
    return selectedItems.some(item => !item.favorite)
  }

  const handleUploadFiles = useUploadTimelineItemsUploadHandler(type, filter)

  const handleDownloadFiles = () => {
    dispatch(downloadTimelineItemsThunk({ items: selectedItems, type }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_DOWNLOAD_CLICK)
  }

  const handleCreateAlbumWithSelectedItems = () => {
    const callback = (id: string) => {
      if (type === SDK_TYPES.DEFAULT) {
        navigate(`/photos/albums/${id}`)
      }

      if (type === SDK_TYPES.FAMILY) {
        navigate(`/family/photos/albums/${id}`)
      }

      analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_NEW_ALBUM_CLICK)
    }

    dispatch(createNewAlbumWithItemsThunk({
      items: selectedItems,
      callback,
      type: type === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleOpenRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(true)
  }

  const handleCloseRemovingConfirmationModal = () => {
    toggleRemovingConfirmationModal(false)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveItems = () => {
    dispatch(removeSelectedItemsThunk({ items: selectedItems, type }))
    handleCloseRemovingConfirmationModal()
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_REMOVE_CLICK)
  }

  const handleShareItems = () => {
    dispatch(createAndShareAlbumThunk({
      items: selectedItems,
      type: type === SDK_TYPES.DEFAULT ? SDK_TYPES.DEFAULT : SDK_TYPES.FAMILY
    }))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_CREATE_LINK)
  }

  const handleCopyToFamily = () => {
    dispatch(copyTimelineItemsToFamilyThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTO_COPY_TO_FC_CLICK)
  }

  const handleCopyToPersonalCloud = () => {
    dispatch(copyTimelineItemsToPersonalCloudThunk(selectedItems))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleAddToFavoritesItems = () => {
    dispatch(addSelectedToFavoritesItemsThunk({ items: selectedItems, withNotification: true, type }))
    dispatch(timelineActions.unselectAll())
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_ADD_TO_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleRemoveFromFavoritesItems = () => {
    dispatch(removeSelectedFromFavoritesItemsThunk({ items: selectedItems, type }))
    dispatch(timelineActions.unselectAll())
    analytics.push(ANALYTICS_EVENTS.WEB_PHOTOS_ACTIONS_REMOVE_FROM_FAVORITES)
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const items = getTimelineItemsSelector()
  const totalItemsCount = getTimelineTotalItemsCountSelector()

  const handleDuplicate = () => {
    if (selectedItems.length === 1) {
      dispatch(openPreviewDuplicateThunk({ selectedItem: selectedItems[0], items, totalItemsCount, type }))
      dispatch(appActions.toggleRightMenuOnMobile(false))
    }
  }

  const menuItems = (() => {
    const items: React.ComponentProps<typeof MenuItem>[] = [
      {
        label: t('a_common_createAlbumButton'),
        iconName: 'create_album',
        onClickItem: handleCreateAlbumWithSelectedItems
      },
      isSelectedItemsHaveOnlyFavorite() && {
        label: t('a_common_addToFavorites'),
        iconName: 'heart',
        onClickItem: handleAddToFavoritesItems
      },
      !isSelectedItemsHaveOnlyFavorite() && (type === SDK_TYPES.DEFAULT || type === SDK_TYPES.FAMILY) && {
        label: t('a_common_removeFavorite'),
        iconName: 'remove_favorite',
        onClickItem: handleRemoveFromFavoritesItems
      },
      {
        label: t('a_common_createPublicLink'),
        iconName: 'link',
        onClickItem: handleShareItems
      },
      isEnabledPhotoDuplicates && selectedItems.length === 1 && selectedItems[0].type === 'image' && {
        label: t('a_common_searchForSimilarPhotos'),
        iconName: 'search_duplicate',
        onClickItem: handleDuplicate
      },
      {
        label: t('a_common_download'),
        iconName: 'download',
        onClickItem: handleDownloadFiles
      },
      {
        label: t('a_common_remove'),
        iconName: 'delete',
        onClickItem: handleOpenRemovingConfirmationModal
      },
    ].filter(item => !!item)

    if (!!userData?.family_user_id) {
      items.unshift({
        label: type === SDK_TYPES.FAMILY ? t('a_common_copyToPersonal') : t('a_common_copyToFamily'),
        iconName: type === SDK_TYPES.FAMILY ? 'add_photo_alternate_1' : 'family',
        onClickItem: type === SDK_TYPES.FAMILY ? handleCopyToPersonalCloud : handleCopyToFamily
      })
    }

    return items
  })()

  const mobileMenuItems = (() => {
    let items: React.ComponentProps<typeof MenuItem>[] = [
      !selectedItemsCount && {
        label: t('a_common_uploadPhotos'),
        iconName: 'download2',
        type: 'file',
        onClickItem: handleUploadFiles,
        accept: contentTypesUpload
      }
    ]

    if (!!selectedItemsCount) {
      items = [...items, ...menuItems]
    }

    return items.filter(Boolean)
  })()

  if (type === SDK_TYPES.FAMILY && !userData?.family_user_id) {
    return null
  }

  return (
    <>
      <TimelineToolbarBox className={className}>
        <StyledPrimaryButton
          actionName={t('a_common_uploadPhotos')}
          htmlFor="upload-btn"
          className={FAMILY_ONBOARDING_CLASSES.STEP_2}
        >
          {t('a_common_uploadPhotos')}

          <HiddenFilesInput
            id="upload-btn"
            onChange={handleUploadFiles}
            type="file"
            multiple
            accept={contentTypesUpload}
          />
        </StyledPrimaryButton>

        {
          !!selectedItemsCount &&
          <MenuWithActionItems menuItems={menuItems} />
        }
      </TimelineToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={!!selectedItemsCount && (
          <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
        )}
      />

      <DialogModal
        isOpened={removingConfirmationModalOpened}
        title={t('l_common_headerRemovePhotos', { number: selectedItemsCount })}
        onClose={handleCloseRemovingConfirmationModal}
        okText={t('a_common_ok')}
        onOk={handleRemoveItems}
        cancelText={t('a_common_cancel')}
        onCancel={handleCloseRemovingConfirmationModal}
      >
        <TextInModalBox>
          {t('l_common_areYouSure')}
        </TextInModalBox>
      </DialogModal>
    </>
  )
}

const TextInModalBox = styled.span`
  font-size: 14px;
`

const TimelineToolbarBox = styled.div`
  padding-right: 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled.label`
  background: var(--button-primary-default);
  border: 0;
  border-radius: 4px;
  color: var(--button-primary-text);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s linear;

  &:hover {
    background: var(--button-primary-hover);
  }

  &:focus, &:active {
    background: var(--button-primary-hover);
  }
`

const HiddenFilesInput = styled.input`
  display: none;
`
