import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { WS_EVENTS_NAMES } from 'constants/wsEventsNames'

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { getFamilySdk } from 'sdk/family'
import { IFamilySchema } from '@cloudike/web_photos/dist/types/intarfaces/IFamilySchema'
import { IMember } from '@cloudike/web_photos/dist/types/intarfaces/IMember'
import { RootState } from 'store'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { getErrorData } from 'utils/getErrorData'
import { getPhotosWS } from 'sdk/photo'
import { t } from 'i18next'
import { fetchUserSettingsThunk, updateUserDataThunk } from 'features/user/userSlice'
import { NavigateFunction } from 'react-router-dom'
import { getPhotoTimelineSdk, getTimelineSdkByType } from 'sdk/timeline'
import { OrderType, SelectType } from "@cloudike/web_ui_components"
import _ from "lodash"

import { getErrorByFieldName } from "../../utils/utils"
import { TOTAL_COUNT_HEADER } from "../../constants/headers"
import { SDK_TYPES } from "../../sdk/sdkConstants"
import { userApi } from "../../api/userApi"
import { SortColumns } from "../files/filesSlice"
import {
  CheckboxVisibilityType,
  isMemberSelectablePredicate,
  mapIsSelectable,
  selectAllMembersListItemsReducer,
  selectMembersListItemReducer,
  selectMembersListItemWithPressedCtrlReducer,
  selectMembersListItemWithPressedShiftReducer,
  unselectAllMembersListItemsReducer
} from "../../utils/familyMembersListSelection"
import { familyApi } from "../../api/familyApi"

interface State {
  status: LOADING_STATUSES,
  familyData: IFamilySchema,
  sort: { by: SortColumns, direction: OrderType },
  selectedItemsIds: string[],
  selectType: SelectType,
  checkboxVisibility: CheckboxVisibilityType,
  lastSelectedIndex: number | null,
  lastSelectedWithShiftIndex: number | null,
  renamingItemId: string | null,
  error?: string,
  isFamilyInviteModalOpened: boolean,
  userHasTimelineItems: boolean,
  isOnboardingActive: boolean,
  onboardingStep: number
}

export const subscribeFamilyToWSThunk = createAsyncThunk(
  'family/subscribeFamilyToWSThunk',
  async function ({ navigate }: { navigate: NavigateFunction }, { dispatch, getState }) {
    const state = getState() as RootState
    const photosWs = getPhotosWS()

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_MEMBER_REVOKED, ({ output }) => {
      if (Number(state.user.userData.id) === Number(output.id)) {
        dispatch(updateUserDataThunk({ family_user_id: null, is_owner_family: null }))
        dispatch(actions.resetState())
        navigate('/family/photos')

        return
      }
      dispatch(actions.deleteMemberById(output.id))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_USER_JOINED, ({ output }) => {
      dispatch(actions.addMember({ member: output, userId: state.user.userData.id }))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_MEMBER_LEFT, ({ output }) => {
      dispatch(actions.deleteMemberById(output.id))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_CHANGED, ({ output }) => {
      dispatch(actions.updateFamilyData(output))
    })

    photosWs.addEventListener(WS_EVENTS_NAMES.FAMILY_DELETED, () => {
      dispatch(updateUserDataThunk({ family_user_id: null, is_owner_family: null }))
      dispatch(actions.resetState())
      navigate('/family/photos')
      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_familyDeleted')
      })
    })
  }
)

export const unsubscribeFamilyFromWSThunk = createAsyncThunk(
  'family/subscribeFamilyToWSThunk',
  async function () {
    const photosWs = getPhotosWS()

    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_USER_JOINED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_MEMBER_LEFT)
    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_CHANGED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_MEMBER_REVOKED)
    photosWs.removeEventListener(WS_EVENTS_NAMES.FAMILY_DELETED)
  }
)

export const fetchFamilyThunk = createAsyncThunk(
  'family/fetchFamilyThunk',
  async function (_, { getState }) {
    const sdk = getFamilySdk()
    const state = getState() as RootState
    const userId = state.user.userData.id
    const familyDataResponse = await sdk.getUserFamilies({ limit: 1 })
    const isUserFamilyOwner = familyDataResponse.data._embedded.families[0].owner_id === userId
    const familyData = familyDataResponse.data._embedded.families[0]

    const familyMembers = await (await sdk.getFamilyMembers(familyData.id, { limit: 1000 })).data._embedded.members
    const selectableMembers = mapIsSelectable(userId, familyMembers, isUserFamilyOwner)
    return {
      familyData,
      familyMembers: selectableMembers
    }
  }
)

export const checkTimelineItemsCountThunk = createAsyncThunk(
  'family/checkTimelineItems',
  async function (_, { dispatch }) {
    const timelineSdk = getTimelineSdkByType(SDK_TYPES.DEFAULT)

    const timelineItemsResponse = await timelineSdk.getTimelineItems({ offset: 0, limit: 1, total_count: true })
    const userHasTimelineItems = Number(timelineItemsResponse.headers[TOTAL_COUNT_HEADER]) > 0

    dispatch(actions.setUserHasTimelineItems(userHasTimelineItems))
  }
)

export const deleteFamilyMembersThunk = createAsyncThunk(
  'family/deleteFamilyMembersThunk',
  async function (members: IMember[], { getState, dispatch }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState
      const familyId = state.family.familyData.id
      const notificationTitle = t('l_notification_memberFamilyDeleted', { number: members.length })

      showGlobalProgressLoader()

      for (const member of members) {
        await sdk.deleteFamilyMember(familyId, member.id)
        dispatch(actions.deleteMember(member))
      }

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: notificationTitle
      })

    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const deleteFamilyLinkThunk = createAsyncThunk(
  'family/deleteFamilyLinkThunk',
  async function (_, { getState }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      const response = await sdk.lockUserFamily(familyId)

      hideGlobalProgressLoader()
      return response.data
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const createFamilyLinkThunk = createAsyncThunk(
  'family/createFamilyLinkThunk',
  async function (_, { getState }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      const response = await sdk.unlockUserFamily(familyId)

      hideGlobalProgressLoader()
      return response.data
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const deleteFamilyCloudThunk = createAsyncThunk(
  'family/deleteFamilyCloudThunk',
  async function (callback: () => void, { getState, dispatch }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      dispatch(actions.resetStatus())

      await sdk.deleteUserFamily(familyId)

      callback()
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const createFamilyCloudThunk = createAsyncThunk(
  'family/createFamilyCloudThunk',
  async function ({ callback }: {callback?: (familyUserId: number, ownerId: number) => void} , { dispatch }) {
    try {
      const sdk = getFamilySdk()
      const timelineSdk = getPhotoTimelineSdk()

      showGlobalProgressLoader()

      const familyData = (await sdk.createUserFamily({})).data

      // const timelineItemsResponse = await timelineSdk.getTimelineItems({ offset: 0, limit: 1, total_count: true })
      //
      // const userHasTimelineItems = Number(timelineItemsResponse.headers[TOTAL_COUNT_HEADER]) > 0

      dispatch(actions.setFamilyData(familyData))

      if (callback) {
        callback(familyData.shared_user_id, familyData.owner_id)
      }
    } catch (error) {
      const errorData = getErrorByFieldName(error, 'response')
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(errorData)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const leaveFromFamilyCloudThunk = createAsyncThunk(
  'family/leaveFromFamilyCloudThunk',
  async function ({ userId, callback }: { callback: () => void, userId: string }, { getState, dispatch }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState

      const familyId = state.family.familyData.id

      showGlobalProgressLoader()

      dispatch(actions.resetStatus())

      await sdk.deleteFamilyMember(familyId, userId)


      callback()
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const joinToFamilyCloudThunk = createAsyncThunk(
  'family/joinToFamilyCloudThunk',
  async function ({ hash, successCallback, errorCallback }: { successCallback: (id: number, ownerId: number) => void, errorCallback: (error: any) => void, hash: string }) {
    try {
      showGlobalProgressLoader()
      const sdk = getFamilySdk()

      await sdk.joinFamilyMember(hash, {})
      const familyDataResponse = await sdk.getUserFamilies({ limit: 1 })

      const familyData = familyDataResponse.data._embedded.families[0]

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_youWereAddedtoFamily')
      })

      successCallback(familyData?.shared_user_id, familyData?.owner_id)
    } catch (error) {
      errorCallback(error.cause)
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const finishOnboardingThunk = createAsyncThunk(
  'family/finishOnboardingThunk',
  async function (_, { getState, dispatch }) {
    const userId = (getState() as RootState).user.userData.id
    try {
      await userApi.updateUserSetting(userId, 'family.onboarding', { web_finished: true })
      dispatch(fetchUserSettingsThunk())
    } catch (error) {}
  }
)

export const sendInvitesToFamily = createAsyncThunk(
  'family/sendInvitesToFamily',
  async function ({ to }: {to:  string[]}, { getState, dispatch }) {
    showGlobalProgressLoader()

    const state = getState() as RootState
    const userId = state.user.userData.id
    const familyData = state.family.familyData

    try {
      if (to.length) {
        const result = await Promise.allSettled(to.map(phoneOrEmail => familyApi.sendInviteToFamily(userId, familyData.id, phoneOrEmail)))

        const successfullyResolved = result.filter(promiseResult => promiseResult.status === 'fulfilled')
        const rejected = result.filter(promiseResult => promiseResult.status === 'rejected')

        const hasRejected = rejected.some((result: any) => result?.reason?.code === 'InvalidParameters')

        showNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          title: hasRejected ?
            `${t('l_notification_familyInviteSentToUsers', { 0: successfullyResolved.length, 1: to.length })} ${t('l_notification_checkInfo')}`
            : t('l_notification_familyInviteSentToUsers', { 0: successfullyResolved.length, 1: to.length })
        })
      }

      dispatch(updateUserDataThunk({ family_user_id: familyData?.shared_user_id, is_owner_family: userId === familyData?.owner_id }))
    } catch (error) {}
    finally {
      hideGlobalProgressLoader()
      dispatch(actions.toggleFamilyinviteModal(false))
    }
  }
)

export const updateFamilyMemberThunk = createAsyncThunk(
  'family/updateFamilyMemberThunk',
  async function ({ member, data  }: { member: IMember, data: Partial<IMember> }, { dispatch, getState }) {
    try {
      const sdk = getFamilySdk()
      const state = getState() as RootState
      const familyId = state.family.familyData.id
      const notificationTitle = t('l_notification_familyMemberRenamed')
      showGlobalProgressLoader()

      const { data:newData } = await sdk.updateFamilyMember(familyId, member.id, data)
      dispatch(actions.updateFamilyMember({ member, data: newData }))

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: notificationTitle
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        ...getErrorData(error)
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

const adapter = createEntityAdapter<IMember>()

export const familyMemberSelectors = adapter.getSelectors()


const initialState = adapter.getInitialState({
  status: LOADING_STATUSES.LOADING,
  sort: { by: SortColumns.NAME, direction: OrderType.ASK },
  selectedItemsIds: [],
  selectType: SelectType.NONE,
  checkboxVisibility: CheckboxVisibilityType.HIDDEN,
  renamingItemId: null,
  familyData: {},
  lastSelectedIndex: null,
  lastSelectedWithShiftIndex: null,
  error: '',
  isFamilyInviteModalOpened: false,
  userHasTimelineItems: false,
  isOnboardingActive: false,
  onboardingStep: 1
} as State)

const sortNodes = (nodes, { by, direction }) => {
  const orderByType = (entity) => entity.type

  return _.chain(nodes)
    .orderBy(by === 'name' ? node => node.name.toUpperCase() : by, [direction])
    .orderBy(orderByType)
    .value()
}

export const familySlice = createSlice({
  name: 'family',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = LOADING_STATUSES.LOADING
    },
    setFamilyData: (state, action) => {
      state.familyData = action.payload
    },
    updateFamilyData: (state, action) => {
      state.familyData = { ...state.familyData, ...action.payload }
    },
    deleteMember: (state, action) => {
      adapter.removeOne(state, action.payload.id)
      unselectAllMembersListItemsReducer(state)
    },
    deleteMemberById: (state, action) => {
      adapter.removeOne(state, action.payload)
      unselectAllMembersListItemsReducer(state)
    },
    addMember: (state, action) => {
      const { member, userId } = action.payload
      const isMemberSelectable = isMemberSelectablePredicate(userId, member)
      adapter.addOne(state, { ...member, isSelectable: isMemberSelectable, isCheckboxHidden: !isMemberSelectable })
    },
    toggleFamilyinviteModal: (state, action) => {
      state.isFamilyInviteModalOpened = action.payload
    },
    resetState: (state) => {
      state.familyData = {} as IFamilySchema
      adapter.removeAll(state)
    },
    setUserHasTimelineItems: (state, action) => {
      state.userHasTimelineItems = action.payload
    },
    setIsOnboardingActive: (state, action) => {
      state.isOnboardingActive = action.payload
    },
    setOnboardingStep: (state, action) => {
      state.onboardingStep = action.payload
    },
    setSort: (state, action) => {
      state.sort = action.payload

      const nodes = sortNodes(familyMemberSelectors.selectAll(state), state.sort)

      adapter.setAll(state, nodes)
    },
    updateFamilyMember: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.member.id,
        changes: action.payload.data
      })
    },
    selectItem: selectMembersListItemReducer(familyMemberSelectors),
    selectItemWithPressedCtrl: selectMembersListItemWithPressedCtrlReducer(familyMemberSelectors),
    selectItemWithPressedShift: selectMembersListItemWithPressedShiftReducer(familyMemberSelectors),
    selectAll: selectAllMembersListItemsReducer,
    unselectAll: unselectAllMembersListItemsReducer,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFamilyThunk.fulfilled, (state, action) => {
        state.familyData = action.payload.familyData
        const members = sortNodes(action.payload.familyMembers, state.sort)
        adapter.setAll(state, members)
        state.status = LOADING_STATUSES.SUCCEEDED
      })
      .addCase(fetchFamilyThunk.rejected, (state, action) => {
        state.status = LOADING_STATUSES.FAILED
        state.error = action.error.message
      })
      .addCase(deleteFamilyLinkThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.familyData = action.payload
        }
      })
      .addCase(createFamilyLinkThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.familyData = action.payload
        }
      })
  },
})

const {
  reducer, actions
} = familySlice

export { reducer as familyReducer, actions as familyActions }
