import { useEffect, useRef } from "react"

import { FilesTableHeader, FilesTableRow, FilesTableTypes, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from "react-i18next"
import { useVirtualizer } from '@tanstack/react-virtual'
import classNames from "classnames"

import { getUiTheme } from "../user/selectors"

import { validationSchemaForFileItem } from './validationSchemaForFileItem'
import { formatFileName } from "./filesUtils"


export const FilesList = ({
  items,
  columns,
  selectType,
  checkboxVisibility,
  onChangeFolder,
  onToggleItem,
  onRenameItem,
  onSortingChange,
  onClickAllCheckbox,
  onClickCheckbox,
  selectedFilesIds,
  renamingFileId = null,
  type = FilesTableTypes.default,
  fileForScrollingId = null,
  withoutContextMenu = false,
  contextMenuItems = [],
  onRightClick,
  onEditPublicLink,
  isActiveSearch = false,
  isTrash = false
}) => {
  const { t } = useTranslation()
  const theme = getUiTheme()

  const listRef = useRef(null)

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    estimateSize: () => 56,
    overscan: 5,
    getScrollElement: () => listRef.current
  })

  useEffect(() => {
    if (!fileForScrollingId || !items.length) {
      return
    }

    const index = items.findIndex((item) => item.id === fileForScrollingId)

    if (index !== -1) {
      rowVirtualizer.scrollToIndex(index)
    }
  }, [fileForScrollingId, items])

  const handleRenameItem = (_, newName: string) => {
    onRenameItem(newName)
  }

  const allFileIds = items.map((item) => item.id)

  const filesTableMobileBreakpoint = 767

  const filesList = rowVirtualizer.getVirtualItems().map((virtualItem) => {
    const item = items[virtualItem.index]

    return (
      <SFilesTableRow
        theme={theme}
        onClickCheckbox={() => onClickCheckbox(item, items)}
        mobileBreakpoint={filesTableMobileBreakpoint}
        menuItems={contextMenuItems}
        sharedPromptText={item?.is_shared ? t('a_common_editPublicLink') : t('a_common_createPublicLink')}
        onRightClick={() => {
          onRightClick(item)
        }}
        item={item}
        formattedName={formatFileName(item.name)}
        onOpenedItem={() => onChangeFolder(item)}
        onSelectedItem={() => onToggleItem(item, items)}
        onRenamedItem={handleRenameItem}
        operationModifiers={{
          selected: selectedFilesIds.includes(item.id),
          renamed: item.id === renamingFileId ? 'renaming' : null
        }}
        validationSchema={validationSchemaForFileItem}
        type={type as FilesTableTypes}
        onEditPublicLink={onEditPublicLink}
        withoutContextMenu={withoutContextMenu}
        key={virtualItem.key}
        height={`${virtualItem.size}px`}
        transform={`translateY(${virtualItem.start}px)`}
      />
    )})

  return (
    <>
      <FileListBox checkboxVisibility={checkboxVisibility}
        className="js-files-list"
      >
        <FilesTableHeader
          columns={columns}
          selectType={selectType}
          onSelectItems={() => {
            onClickAllCheckbox(allFileIds)
          }}
          onSortingChange={onSortingChange}
          type={type}
          isActiveSearch={isActiveSearch}
        />

        <FileListItemsWrapper className={classNames({ isTrash: isTrash })}
          isActiveSearch={isActiveSearch}
          height={isActiveSearch ? '368px' : '119px'}
          ref={listRef}
        >
          <FileListItemsInnerWrapper height={rowVirtualizer.getTotalSize() + 'px'}>
            {filesList}
          </FileListItemsInnerWrapper>
        </FileListItemsWrapper>
      </FileListBox>
    </>
  )
}

const FileListBox = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;

  & .tableRow__check-hover {
    visibility: ${props => props.checkboxVisibility};
  }
`

const FileListItemsWrapper = styled.div`
  position: relative;
  height: calc(100% - ${(props:any) => props.height});
  overflow-y: scroll;
  margin-left: -36px;
  padding-left: 36px;
  padding-right: ${(props:any) => {return props.isActiveSearch ? '' : '20px'}};
  width: calc(100% + 35px);
  overflow-x: hidden;
  
  &.isTrash {
    height: calc(100% - 214px);
  }

  &::-webkit-scrollbar {
    width: 4px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 14px;

    :hover{
      cursor: pointer;
    }
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
`

const FileListItemsInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height};
`

const SFilesTableRow = styled(FilesTableRow)`
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.height};
  transform: ${props => props.transform};
`
