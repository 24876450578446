import { useCallback, useEffect } from "react"

import styled from 'styled-components'
import { usePageTitle } from "features/common/hooks/usePageTitle"
import { PageTopbar } from "features/common/page-topbar"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from  "store"
import { CreateWallet } from "features/documents/CreateWallet"
import { SignInWallet } from "features/documents/SigInWallet"
import {
  documentsActions,
  fetchCryptoAlgorithmsThunk,
  fetchDocumentsUserSettingsThunk,
  fetchPreviewSizesThunk,
  resetDocumentsAccessTimerThunk
} from "features/documents/documentsSlice"
import {
  getDocumentSelectedTypesCountSelector,
  getDocumentsMasterKeySelector,
  getDocumentsWalletLoadingStatusSelector
} from "features/documents/selectors"
import { LoadingOverlay } from "ui/LoadingOverlay"
import { useDocumentsWalletAccessChecking } from "features/documents/useDocumentsWalletAccessChecking"
import { DocumentsTypes } from "features/documents/DocumentsTypes"
import { DOCUMENTS_WALLET_STATUSES } from "features/documents/types"
import { STYLED_VARIABLES } from "@cloudike/web_ui_components"
import { MobileToolbarMenuToggle } from "features/common/right-sidebar/MobileToolbarMenuToggle"

import { appActions } from "../../store/app"
import { useMobileDetection } from "../../features/common/hooks"

export enum TypeModal {
    CREATE = 'create',
    RENAME = 'rename'
}

const DocumentsPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const status = getDocumentsWalletLoadingStatusSelector()
  const isMobile = useMobileDetection()
  const selectedTypesCount = getDocumentSelectedTypesCountSelector()

  const masterKey = getDocumentsMasterKeySelector()

  useDocumentsWalletAccessChecking()
  usePageTitle('a_nav_documentWallet')

  useEffect(() => {
    if (status === DOCUMENTS_WALLET_STATUSES.CREATION || status === DOCUMENTS_WALLET_STATUSES.SIGN_IN) {
      dispatch(appActions.toggleLayoutOverflowEnabling(true))
      dispatch(appActions.setIsRightSidebarTransparent(true))
    }

    return () => {
      dispatch(appActions.toggleLayoutOverflowEnabling(false))
      dispatch(documentsActions.setShowCreateModal(false))
      dispatch(appActions.setIsRightSidebarTransparent(false))
    }
  }, [status])

  useEffect(() => {
    if (masterKey && status === DOCUMENTS_WALLET_STATUSES.FIRST_LOADING) {
      dispatch(resetDocumentsAccessTimerThunk())

      dispatch(documentsActions.setLoadingStatus(DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADED))
    }

    if (!masterKey && status === DOCUMENTS_WALLET_STATUSES.FIRST_LOADING) {
      dispatch(fetchDocumentsUserSettingsThunk())
      dispatch(fetchCryptoAlgorithmsThunk())
      dispatch(fetchPreviewSizesThunk())
    }
  }, [masterKey, status])

  const handleCancelSelection = useCallback(() => {
    dispatch(documentsActions.unselectAllTypes())
  }, [])

  return (
    <>
      <SPageTopbar title={t('a_nav_documentWallet')}
        selectedText={isMobile ? t('l_common_selectedObjects', { number: selectedTypesCount }) : t('l_files_selectedFiles', { number: selectedTypesCount })}
        selectedItemsCount={selectedTypesCount}
        onCancelSelection={handleCancelSelection}
      />

      {
        [DOCUMENTS_WALLET_STATUSES.FIRST_LOADING].includes(status) && (
          <LoadingOverlay
            text={t('l_common_loading')}
            visible={true}
          />
        )
      }

      {
        [DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADING].includes(status) && (
          <LoadingOverlay
            text={t('l_notification_openingDW')}
            visible={true}
          />
        )
      }

      {
        [DOCUMENTS_WALLET_STATUSES.CREATION_IN_PROGRESS].includes(status) && (
          <LoadingOverlay
            text={t('l_notification_creatingDW')}
            visible={true}
          />
        )
      }

      {
        [DOCUMENTS_WALLET_STATUSES.DELETING_IN_PROGESS].includes(status) && (
          <LoadingOverlay
            text={t('l_notification_deletingDW')}
            visible={true}
          />
        )
      }

      {
        [DOCUMENTS_WALLET_STATUSES.SIGN_IN, DOCUMENTS_WALLET_STATUSES.CREATION, DOCUMENTS_WALLET_STATUSES.CREATION_IN_PROGRESS].includes(status) && (
          <CreateWalletWrapper>
            {[DOCUMENTS_WALLET_STATUSES.SIGN_IN, DOCUMENTS_WALLET_STATUSES.DELETING_IN_PROGESS].includes(status) &&
                            <SignInWallet />}

            {[DOCUMENTS_WALLET_STATUSES.CREATION, DOCUMENTS_WALLET_STATUSES.CREATION_IN_PROGRESS].includes(status) &&
                            <CreateWallet />}
          </CreateWalletWrapper>
        )
      }

      {
        status === DOCUMENTS_WALLET_STATUSES.DOCUMENT_TYPES_LOADED && (
          <>
            <DocumentsTypes />

            <MobileToolbarMenuToggle />
          </>
        )
      }
    </>
  )
}

const SPageTopbar = styled(PageTopbar)`
  padding-bottom: 20px;
`

const CreateWalletWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  position: relative;
  z-index: 14;
  left: -36px;
  height: calc(100% - 80px);
  width: calc(100% + 290px);
  overflow: hidden;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    padding-top: 20px;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: 100%;
    left: 0;
  }
`

export default DocumentsPage
