import { STYLED_VARIABLES } from 'constants/styledVariables'

import { MenuWithActionItems, PrimaryButton } from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from "store"
import { MobileToolbar } from 'features/common/right-sidebar/MobileToolbar'
import { sharingActions } from 'features/sharing/sharingSlice'
import { SDK_TYPES } from 'sdk/sdkConstants'
import { AlbumType } from '@cloudike/web_photos'
import { appActions } from 'store/app'
import { FileIcon } from 'features/common/file-icon/FileIcon'
import { NodeTypes } from '@cloudike/web_files'
import { convertFromBytes } from 'utils/utils'
import moment from 'moment'

import { MobileToolbarCounter } from "../common/mobile-toolbar-counter/MobileToolbarCounter"

import { getPublicLinksFilesSelectedItemsIdsSelector, getPublicLinksFilesSelectedItemsSelector } from './selectors'
import { downloadNodesThunk } from './publicLinksFilesSlice'

interface PublicLinksToolbarProps {
  className?: string,
}

const convertFileTypeToIcon = (type) => {
  if (type === NodeTypes.DIR) {
    return 'folder'
  }

  if (type !== null) {
    switch (type) {
    case 'image':
      return 'file_image'
    case 'video':
      return 'file_video'
    case 'audio':
      return 'file_audio'
    }
  }
}

export const PublicLinksFilesToolbar: React.FC<PublicLinksToolbarProps> = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedItems = getPublicLinksFilesSelectedItemsSelector()
  const selectedItemsCount = selectedItems.length
  const selectedItemsIds = getPublicLinksFilesSelectedItemsIdsSelector()

  const handleEditPublicLink = () => {
    dispatch(sharingActions.setSharingAlbumConfig({
      sdkType: SDK_TYPES.FILES,
      sharedAlbumType: AlbumType.SHARED
    }))
    dispatch(sharingActions.setSharedItemData(selectedItems[0]))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const handleDownloadSelected = () => {
    dispatch(downloadNodesThunk({ ids: selectedItemsIds }))
    dispatch(appActions.toggleRightMenuOnMobile(false))
  }

  const menuItems = (() => {
    const items = [
      selectedItems.length === 1 && {
        label: t('a_common_editPublicLink'),
        iconName: 'link',
        onClickItem: handleEditPublicLink
      }
    ]

    return items.filter(Boolean)
  })()

  const mobileMenuItems = [
    ...menuItems,
    {
      label: t('a_common_download'),
      iconName: 'download',
      onClickItem: handleDownloadSelected,
    }
  ]

  const headerContent = () => {
    if (!selectedItemsCount) {
      return null
    }

    if (selectedItemsCount > 1) {
      return (
        <MobileToolbarCounter selectedItemsCount={selectedItemsCount} />
      )
    } else {
      const node = selectedItems[0]

      return (
        <StyledMobileToolbarHeader>
          <StyledFileIcon fileType={convertFileTypeToIcon(node.type)} />

          <StyledFileInfoBox>
            {node.name}

            {
              node.type !== NodeTypes.DIR && (
                <StyledFileInfo>
                  {convertFromBytes(node.file_info.size)}

                  {' - '}

                  {
                    moment(node.updated).utc(true).format('MMM DD, yyyy, HH:mm')
                  }
                </StyledFileInfo>
              )}
          </StyledFileInfoBox>


        </StyledMobileToolbarHeader>
      )
    }
  }

  return (
    <>
      <PublicLinksFilesToolbarBox className={className}>
        {
          !!selectedItemsCount && (
            <StyledPrimaryButton
              actionName={t('a_common_download')}
              onAction={handleDownloadSelected}
            >
              {t('a_common_download')}
            </StyledPrimaryButton>
          )
        }

        {
          selectedItemsCount === 1 &&
          <MenuWithActionItems menuItems={menuItems} />
        }
      </PublicLinksFilesToolbarBox>

      <MobileToolbar
        items={mobileMenuItems}
        headContent={headerContent()}
      />
    </>
  )
}

const PublicLinksFilesToolbarBox = styled.div`
  padding: 0 24px;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    display: none;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`

const StyledMobileToolbarHeader = styled.div`
  width: 100%;
  padding: 24px 16px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid var(--stroke-primary);
`

const StyledFileIcon = styled(FileIcon)`
  width: 32px;
  height: 32px;
`

const StyledFileInfoBox = styled.div`
  margin-left: 16px;
`

const StyledFileInfo = styled.p`
  color: var(--text-secondary);
  font-size: 13px;
  font-weight: 400;
`
