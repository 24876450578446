import { formatImgUrl } from 'constants/configs/theme.config'

import React, { useMemo, useRef, useState } from 'react'

import { Formik } from 'formik'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton, SpriteIcon, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import { PasswordInput } from 'ui/PasswordInput'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'ui/Checkbox'
import { LoadingOverlay } from 'ui/LoadingOverlay'
import { generatePassword } from 'utils/passwordGeneration'
import * as yup from 'yup'
import { useAppDispatch } from "store"
import { analytics, ANALYTICS_EVENTS } from 'features/common/analytics'
import { FormikProps } from "formik/dist/types"

import { getUiTheme } from "../user/selectors"
import { THEME } from "../user/userSlice"
import { ModalNotification } from "../../ui/ModalNotification"

import { createDocumentsWalletPasswordThunk, documentsActions } from './documentsSlice'
import { getDocumentsWalletLoadingStatusSelector, getNotificationModalTextSelector } from './selectors'
import { DOCUMENTS_WALLET_STATUSES } from './types'
import { CREATE_WALLET_INPUT_MAX_LENGTH, CREATE_WALLET_INPUT_MIN_LENGTH } from './constants'
import { DocumentsBlurredSkeleton } from './DocumentsBlurredSkeleton'
import { CreatePasswordWarningModal } from "./CreatePasswordWarningModal"

enum STEPS {
    INFO,
    CREATE
}

export const CreateWallet = ({ className = '' }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [step, setStep] = useState<STEPS>(STEPS.INFO)

  const status = getDocumentsWalletLoadingStatusSelector()
  const modalNotificationText = getNotificationModalTextSelector()
  const initialValues = { password: '' }
  const [warningModalOpened, togglePasswordWarningModal] = useState(false)

  const inputRef = useRef<HTMLInputElement>()

  const theme = getUiTheme()

  const formikRef = useRef<FormikProps<any>>()

  const passwordValidationSchema = useMemo(() => yup.object().shape({
    password: yup
      .string()
      .required(t(''))
      .min(CREATE_WALLET_INPUT_MIN_LENGTH, t(''))
      .max(CREATE_WALLET_INPUT_MAX_LENGTH, t(''))
  }), [])

  const handleCreateClick = () => {
    setStep(STEPS.CREATE)
  }

  const handleSubmit = async (values) => {
    const { password } = values

    if (!!password) {
      await dispatch(createDocumentsWalletPasswordThunk({ password }))
    }
  }

  const handleCopyPassword = (password) => {
    navigator.clipboard.writeText(password)

    analytics.push(ANALYTICS_EVENTS.WEB_DOCUMENTS_COPY_PASSWORD_CLICK)

    dispatch(documentsActions.setNotificationModalText(t('l_notification_passwordCopied')))
  }

  const handleCancel = () => {
    handleHideModalNotification()
    setStep(STEPS.INFO)
  }

  const handleKeyUp = (isValid) => (e) => {
    e.stopPropagation()

    if (e.key === 'Enter' && isValid) {
      togglePasswordWarningModal(true)

      if (inputRef.current) {
        inputRef.current.blur()
      }
    }
  }

  const handleGeneratePassword = (values, setValues) => {
    setValues({ password: generatePassword(10) })

    analytics.push(ANALYTICS_EVENTS.WEB_DOCUMENTS_GENERATE_PASSWORD_CLICK)

    dispatch(documentsActions.setNotificationModalText(t('l_dw_passAutoGenerated')))
  }

  const handleHideModalNotification = () => {
    dispatch(documentsActions.setNotificationModalText(''))
  }

  const handleOpenPasswordWarningModal = () => {
    togglePasswordWarningModal(true)
  }

  const handleClosePasswordWarningModal = () => {
    togglePasswordWarningModal(false)
  }

  if (step === STEPS.INFO) {
    return (
      <CreateWalletFullWidthBox>
        <InfoContent>
          <InfoContentLeft>
            <InfoTitle>
              {t('l_dw_onboardingWelcomeTitle')}
            </InfoTitle>

            <InfoDescription>
              {t('l_dw_onboardingWelcomeMessage')}
            </InfoDescription>

            <CreateWalletButton actionName={t('a_common_createDW')}
              onAction={handleCreateClick}
            />
          </InfoContentLeft>

          <InfoContentRight>
            <InfoImg
              src={theme === THEME.LIGHT ? formatImgUrl("/assets/placeholders-images/onboarding.svg") : formatImgUrl("/assets/placeholders-images-black/onboarding.svg")}
            />
          </InfoContentRight>
        </InfoContent>

        <FullWidthDivider />

        <SInfoOptionsContent>
          <SOptionColumn>
            <SOptionIcon iconName='document_management' />

            <div>
              <SOptionTitle>
                {t('l_dw_onboardingManageTitle')}
              </SOptionTitle>

              <SOptionDescription>
                {t('l_dw_onboardingManageOtherMessage')}
              </SOptionDescription>
            </div>
          </SOptionColumn>

          <SOptionColumn>
            <SOptionIcon iconName='login_lock' />

            <div>
              <SOptionTitle>
                {t('l_dw_onboardingSecurityTitle')}
              </SOptionTitle>

              <SOptionDescription>
                {t('l_dw_onboardingSecurityMessage')}
              </SOptionDescription>
            </div>
          </SOptionColumn>

          <SOptionColumn>
            <SOptionIcon iconName='fill_code' />

            <div>
              <SOptionTitle>
                {t('l_dw_onboardingGroupTitle')}
              </SOptionTitle>

              <SOptionDescription>
                {t('l_dw_onboardingGroupMessage')}
              </SOptionDescription>
            </div>
          </SOptionColumn>
        </SInfoOptionsContent>
      </CreateWalletFullWidthBox>
    )
  }

  return (
    <>
      <DocumentsBlurredSkeleton />

      <CreateWalletBox className={className}>

        <ModalNotification text={modalNotificationText}
          onHide={handleHideModalNotification}
        />

        <SecurityImg src={formatImgUrl("/assets/placeholders-images/security.svg")} />

        <Title>
          {t('l_common_createPassword')}
        </Title>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnMount
          validationSchema={passwordValidationSchema}
          ref={formikRef}
        >
          {({ values, errors, handleChange, handleSubmit, setValues, isValid }) => (
            <>
              <StyledPasswordInput
                placeholder={t('l_common_enterPasswordField')}
                value={values.password}
                onChange={handleChange}
                name="password"
                error={errors.password}
                onKeyUp={handleKeyUp(isValid)}
                maxLength={CREATE_WALLET_INPUT_MAX_LENGTH}
                autoFocus
                ref={inputRef}
              />

              {!!values.password && !errors.password && (
                <CopyPassword onClick={() => handleCopyPassword(values.password)}>
                  {t('a_common_copyPassword')}
                </CopyPassword>
              )}

              <SGeneratePasswordBtn onClick={() => handleGeneratePassword(values, setValues)}>
                {t('l_dw_autoGeneratedPass')}
              </SGeneratePasswordBtn>

              <ButtonBox>
                <CancelButton actionName={t('a_common_cancel')}
                  onAction={handleCancel}
                />

                <CreateWalletSubmitButton
                  actionName={t('a_common_create')}
                  onAction={handleOpenPasswordWarningModal}
                  disabled={!isValid}
                />
              </ButtonBox>


              <CreatePasswordWarningModal
                opened={warningModalOpened}
                onClose={handleClosePasswordWarningModal}
                onOk={handleSubmit}
              />
            </>
          )}
        </Formik>

        <LoadingOverlay text={t('l_notification_creatingDW')}
          visible={status === DOCUMENTS_WALLET_STATUSES.CREATION_IN_PROGRESS}
        />
      </CreateWalletBox>
    </>
  )
}

const CreateWalletBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-right: 10px;
  position: relative;
  background: var(--background-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: 480px;
    min-height: 492px;
    border: 1px solid var(--divider-primary);
    border-radius: 4px;
    padding: 40px 40px 60px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
`

const InfoTextBox = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 12px 0;
  color: var(--text-primary)
`

const SecurityImg = styled.img`
  width: 192px;
  height: 140px;
`

const Title = styled.p`
  margin-top: 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: var(--text-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 20px;
  }
`

const CreateWalletButton = styled(PrimaryButton)`
  margin-top: 32px;
  width: 100%;
  color: var(--button-primary-text);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 48px;
    width: unset;
  }
`

const CreateWalletSubmitButton = styled(PrimaryButton)`
  width: 100%;
`

const StyledPasswordInput = styled(PasswordInput)`
  margin-top: 16px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 32px;
  }
`

const CancelButton = styled(SecondaryButton)`
  width: 100%;
  margin-right: 20px;
`

const CheckboxRow = styled.label`
  display: flex;
  width: 100%;
  color: var(--text-secondary);
  user-select: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

const StyledCheckbox = styled(Checkbox)`
  margin-right: 10px;
  min-width: 20px;
  color: var(--background-primary);
`

const CopyPassword = styled.a`
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--accent-normal);
  width: 100%;
`

const CreateWalletFullWidthBox = styled.div`
  width: calc(100% - 32px);
  background: var(--background-secondary);
  min-height: 468px;
  border: 1px solid var(--divider-primary);
  border-radius: 12px;
  top: 30px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_10}) {
    position: absolute;
    min-height: unset;
    left: 36px;
    width: calc(100% - 66px);
  }
`

const InfoContent = styled.div`
  width: 100%;
  max-width: 1118px;
  padding: 0 24px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP_10}) {
    flex-direction: row;
    padding: 36px 60px 0;
    align-items: unset;
  }
`

const InfoOptionsContent = styled.div`
  width: 100%;
  max-width: 1118px;
  padding: 30px 24px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    margin-top: 25px;
    flex-direction: row;
    padding: 0 60px 25px;
    align-items: center;
  }
`

const FullWidthDivider = styled.div`
  margin-top: 36px;
  width: 100%;
  border-top: 1px solid var(--divider-primary);
`

const InfoContentLeft = styled.div`
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP_10}) {
    width: 448px;
    padding-top: 64px;
    display: block;
  }
`

const InfoContentRight = styled.div`
  width: 294px;
  padding-top: 32px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP_10}) {
    margin-left: 10px;
    width: 543px;
    padding-top: 0;
  }
`

const InfoImg = styled.img`
  width: 294px;
  height: auto;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP_10}) {
    width: 443px;
  }

  @media (min-width: 1380px) {
    width: 543px;
    height: 320px;
  }
`

const InfoTitle = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--text-primary);

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP_10}) {
    font-size: 32px;
    line-height: 130%;
  }
`

const InfoDescription = styled.p`
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--text-primary);
  text-align: center;
  width: 295px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.LAPTOP_10}) {
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 165%;
    width: unset;
    text-align: unset;
  }
`

const SGeneratePasswordBtn = styled.a`
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--accent-normal);
  width: 100%;
`

const SInfoOptionsContent = styled.div`
  width: 100%;
  max-width: 1118px;
  padding: 40px 24px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    display: flex;
    flex-direction: row;
    padding: 32px 60px;
  }
`
const SOptionColumn = styled.div`
  flex: 1;
  display: flex;

  & ~ & {
    margin-top: 24px;
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    display: block;
    
    & ~ & {
      margin-top: 0;
      margin-left: 48px;
    }
  }
`

const SOptionIcon = styled(SpriteIcon)`
  margin-right: 12px;
  width: 24px;
  height: 24px;
  color: var(--active-color, var(--button-primary-default));
  stroke: var(--active-color, var(--button-primary-default));
  stroke-width: 1.6;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-right: 8px;
  }
`

const SOptionTitle = styled.p`
  color: var(--text-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET_100}) {
    margin-top: 12px;
  }
`

const SOptionDescription = styled.p`
  margin-top: 4px;
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

