import { SelectType } from "@cloudike/web_ui_components"
import _ from "lodash"

export enum CheckboxVisibilityType {
  VISIBLE = 'visible',
  HIDDEN = 'hidden'
}


export const changeFilesListCheckboxState = (selectItemsLength, itemsLength, state) => {
  if (selectItemsLength === 0) {
    state.checkboxVisibility = CheckboxVisibilityType.HIDDEN
    state.selectType = SelectType.NONE
  } else {
    if (selectItemsLength < itemsLength && selectItemsLength > 0) {
      state.checkboxVisibility = CheckboxVisibilityType.VISIBLE
      state.selectType = SelectType.SOME
    } else {
      if (selectItemsLength === itemsLength) {
        state.selectType = SelectType.ALL
        state.checkboxVisibility = CheckboxVisibilityType.VISIBLE
      }
    }
  }
}

export const selectFileListItemReducer = (selectors) => (state, action: { payload: { id: string, items: any }}) => {
  const { id, items } = action.payload
  const nodes = selectors.selectAll(state)

  const indexOfItemId = state.selectedItemsIds.indexOf(id)
  const indexOfNode = nodes.findIndex(node => node.id === id)

  if (state.selectedItemsIds.length < 2) {
    if (indexOfItemId === -1) {
      state.selectedItemsIds = [id]
    } else {
      state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
    }
  } else {
    state.selectedItemsIds = [id]
  }

  if (state.selectedItemsIds.length) {
    state.lastSelectedIndex = indexOfNode
  } else {
    state.lastSelectedIndex = null
  }

  state.lastSelectedWithShiftIndex = null

  changeFilesListCheckboxState(state.selectedItemsIds.length, items.length, state)
}

export const selectAllFileListItemsReducer = (state, action) => {
  state.selectType = SelectType.ALL
  state.checkboxVisibility = CheckboxVisibilityType.VISIBLE
  state.selectedItemsIds = [...action.payload]
}

export const unselectAllFileListItemsReducer = (state) => {
  state.selectType = SelectType.NONE
  state.checkboxVisibility = CheckboxVisibilityType.HIDDEN
  state.selectedItemsIds = []
}

export const selectFileListItemWithPressedCtrlReducer = (selectors) => (state, action: { payload: { id: string, items: any }}) => {
  const { id, items } = action.payload

  const nodes = selectors.selectAll(state)

  const indexOfItemId = state.selectedItemsIds.indexOf(id)
  const indexOfNode = nodes.findIndex(node => node.id === id)

  if (indexOfItemId === -1) {
    state.selectedItemsIds = [...state.selectedItemsIds, id]
  } else {
    state.selectedItemsIds = [...state.selectedItemsIds.slice(0, indexOfItemId), ...state.selectedItemsIds.slice(indexOfItemId + 1)]
  }

  if (state.selectedItemsIds.length) {
    state.lastSelectedIndex = indexOfNode
  } else {
    state.lastSelectedIndex = null
  }

  state.lastSelectedWithShiftIndex = null

  changeFilesListCheckboxState(state.selectedItemsIds.length, items.length, state)
}

export const selectFileListItemWithPressedShiftReducer = (selectors) => (state, action: { payload: { id: string, items: any }}) => {
  const { id, items } = action.payload

  const nodes = selectors.selectAll(state)
  const indexOfNode = nodes.findIndex(node => node.id === id)
  const lastSelectedIndex = state.lastSelectedIndex
  const lastSelectedWithShiftIndex = state.lastSelectedWithShiftIndex

  let itemsIdsToExclude = []
  let newIds = []

  if (lastSelectedWithShiftIndex !== null) {
    if (indexOfNode < lastSelectedWithShiftIndex) {
      itemsIdsToExclude = [
        ...nodes.slice(indexOfNode + 1, lastSelectedIndex),
        ...nodes.slice(lastSelectedIndex + 1, lastSelectedWithShiftIndex + 1)
      ]
        .map(node => node.id)
    } else {
      itemsIdsToExclude = [
        ...nodes.slice(lastSelectedWithShiftIndex, lastSelectedIndex),
        ...nodes.slice(lastSelectedIndex + 1, indexOfNode)
      ]
        .map(node => node.id)
    }
  }

  const newState = state.selectedItemsIds.filter(id => !itemsIdsToExclude.includes(id))

  if (indexOfNode >= lastSelectedIndex) {
    newIds = nodes.slice(lastSelectedIndex, indexOfNode + 1).map(node => node.id)
  } else {
    newIds = nodes.slice(indexOfNode, lastSelectedIndex).map(node => node.id)
  }

  state.selectedItemsIds = _.uniq([...newState, ...newIds])
  state.lastSelectedWithShiftIndex = indexOfNode

  changeFilesListCheckboxState(state.selectedItemsIds.length, items.length, state)
}
