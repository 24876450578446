import React, { useEffect } from "react"

import { GlobalStyle } from "styled.global"
import { Routing } from "routing/Routing"
import { useOnlineDetection } from "features/common/hooks/useOnlineDetection"
import { useGlobalDropzone } from "features/common/dropzone/useGlobalDropzone"
import { BrowserRouter as Router, } from "react-router-dom"
import { useUserSettings } from "features/user/hooks/useUserSettings"
import { useAppDispatch } from  "store"

import { setMaxSizeThunk, setUserTimezoneThunk, THEME, userActions } from "./features/user/userSlice"
import { useActivityDetection } from "./features/common/hooks/useActivityDetection"
import FeedbackModal from "./features/common/feedback-modal"
import { getFeedbackModalVisibilitySelector } from "./features/common/selectors"
import { appActions } from "./store/app"
import { getTheme, getUserDataSelector } from "./features/user/selectors"
import { useRefreshWSConnection } from "./features/common/hooks/useRefreshWSConnection"
import NotifAndUploadWidget from "./features/common/notif-and-widget/NotifAndWidget"
import { getSentryWithProfiler, setSentrySessionUser } from "./features/sentry"


const App = React.memo(() => {
  useOnlineDetection()
  useUserSettings()
  useActivityDetection(12)
  useRefreshWSConnection()

  const { getRootProps } = useGlobalDropzone()
  const dispatch = useAppDispatch()
  const userData = getUserDataSelector()

  setSentrySessionUser({ id:userData.id, username: userData.name })

  useEffect(() => {
    if (!userData?.id) {
      return
    }

    dispatch(setMaxSizeThunk())
    dispatch(setUserTimezoneThunk())
  }, [userData?.id])

  const isFeedbackModalVisible = getFeedbackModalVisibilitySelector()

  const handleCloseModal = () => {
    dispatch(appActions.setFeedbackModalVisibility(false))
  }

  const theme = getTheme()
  const handleSystemThemeChange = (e) => {
    if (theme !== THEME.SYSTEM) {
      return
    }

    if (e.matches) {
      dispatch(userActions.setUiTheme(THEME.DARK))
    } else {
      dispatch(userActions.setUiTheme(THEME.LIGHT))
    }
  }
  const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')

  useEffect(() => {
    if (theme === null || theme === THEME.SYSTEM) {
      const currentTheme = darkModeQuery.matches ? THEME.DARK : THEME.LIGHT
      dispatch(userActions.setTheme(THEME.SYSTEM))
      dispatch(userActions.setUiTheme(currentTheme))
    } else {
      dispatch(userActions.setTheme(theme))
      dispatch(userActions.setUiTheme(theme))
    }
    darkModeQuery.addListener(handleSystemThemeChange)
  }, [theme])

  return (
    <div
      id={'presentation'}
      {...getRootProps()}
    >

      <GlobalStyle />

      <Router>
        <Routing />
      </Router>

      <GlobalStyle />

      <NotifAndUploadWidget />

      <FeedbackModal
        onCloseModal={handleCloseModal}
        onSubmitForm={handleCloseModal}
        isShown={isFeedbackModalVisible}
      />
    </div>
  )
})

export default getSentryWithProfiler(App)
