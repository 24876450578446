import { getModalsRootElement } from 'constants/modals'

import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom'
import { PageOverlay, STYLED_VARIABLES } from '@cloudike/web_ui_components'
import styled, { css } from 'styled-components'
import { useAppDispatch } from  "store"
import { getFileContentUrl } from 'utils/files'
import { useDocumentKeyDown } from 'features/common/hooks/useDocumentKeyDown'
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import { getFlashbackAlbumsSelector } from "../timeline/selectors"
import { SDK_TYPES } from "../../../sdk/sdkConstants"
import { useClickAndDoubleClickHandler } from "../../common/hooks/useClickAndDoubleClickHandler"
import { useMobileDetection } from "../../common/hooks"
import { timelineActions } from "../timeline/timelineSlice"
import { analytics, ANALYTICS_EVENTS } from "../../common/analytics"
import {
  ArrowBox,
  ArrowIcon,
  FilePreview,
  ImageBox,
  Img,
  MainContent,
  SHiddenImg,
  StyledSpinner,
  StyledVideo,
  TopRow
} from "../../common/preview-modal/styles"
import { ContentPlaceholder } from "../../common/preview-modal/ContentPlaceholder"
import { getPhotoSdk } from "../../../sdk/photo"
import { NOTIFICATION_TYPES, showNotification } from "../../common/notifications"

import {
  getCurrentPhotoPreviewFlashbackItemIndexSelector,
  getCurrentPhotoPreviewFlashbackModalStatusSelector,
  getPhotoPreviewFlashbackAlbumNameSelector,
  getPhotoPreviewFlashbackErrorSelector,
  getPhotoPreviewFlashbackIndexCurrentAlbumSelector,
  getPhotoPreviewFlashbackItemsSelector,
  getPhotoPreviewFlashbackLoadingMoreSelector,
  getPhotoPreviewFlashbackLoadingSelector,
  getPhotoPreviewFlashbackTotalItemsCountSelector
} from './selectors'
import { PhotoPreviewFlashbackToolbar } from './PhotoPreviewFlashbackToolbar'
import {
  handleNextPhotoFlashbackThunk,
  handlePrevPhotoFlashbackThunk,
  openPhotoPreviewFlashbackThunk,
  photoPreviewFlashbackActions,
  PREVIEW_TYPES
} from "./photoPreviewFlashbackSlice"


export const sortFlashbackAlbums = (albums) => {
  const arr = [...albums]
  const arrViewed = arr.filter(item => item.viewed).filter(item=> item.live_items_count > 0)
  const arrNoViewed = arr.filter(item => !item.viewed).filter(item=> item.live_items_count > 0)
  return [...arrNoViewed.sort((a, b) => b.created - a.created), ...arrViewed.sort((a, b) => b.created - a.created)]
}

export const PhotoPreviewFlashbackModal = () => {
  const dispatch = useAppDispatch()
  const isMobile = useMobileDetection()
  const { t } = useTranslation()

  let timeOut
  let interval
  const defaultDelay = 5000

  const items = getPhotoPreviewFlashbackItemsSelector()
  const totalItemsCount = getPhotoPreviewFlashbackTotalItemsCountSelector()
  const albumName = getPhotoPreviewFlashbackAlbumNameSelector()

  const currentIndex = getCurrentPhotoPreviewFlashbackItemIndexSelector()
  const isModalOpened = getCurrentPhotoPreviewFlashbackModalStatusSelector()
  const isLoading = getPhotoPreviewFlashbackLoadingSelector()
  const isLoadingMore = getPhotoPreviewFlashbackLoadingMoreSelector()
  const hasError = getPhotoPreviewFlashbackErrorSelector()
  const albums = getFlashbackAlbumsSelector()
  const currentIndexAlbum = getPhotoPreviewFlashbackIndexCurrentAlbumSelector()

  const [previewUrl, setPreviewUrl] = useState(null)
  const [indexTimeOut, setIndexTimeOut] = useState(null)
  const [secondTimeOut, setSecondTimeOut] = useState(0)
  const [indexInterval, setIndexInterval] = useState(null)
  const [isBlockClick, setBlockClick] = useState(false)
  const [isPauseSlideShow, setPauseSlideShow] = useState(false)

  useEffect(() => {
    if (items.length !== 0) {
      const line = document.getElementById(items[currentIndex].id)
      if (isPauseSlideShow) {
        line.style.animationPlayState = 'paused'
      }
      if (!isPauseSlideShow) {
        line.style.animationPlayState = 'running'
      }
    }
  }, [isPauseSlideShow])

  const currentItem = items[currentIndex]

  const loadingTimeoutRef = useRef(null)
  const abortControllerRef = useRef(null)

  const handleFinishLoading = () => {
    dispatch(photoPreviewFlashbackActions.setLoading(false))
    clearTimeout(loadingTimeoutRef.current)
  }

  const switchNextAlbum = () => {
    dispatch(photoPreviewFlashbackActions.setIndexCurrentAlbum(currentIndexAlbum + 1))
    dispatch(openPhotoPreviewFlashbackThunk({
      sdkType: SDK_TYPES.DEFAULT,
      type: PREVIEW_TYPES.TIMELINE,
      isViewed: !!albums[currentIndexAlbum + 1].viewed,
      albumName: albums[currentIndexAlbum + 1].description,
      albumId: albums[currentIndexAlbum + 1].id,
    }))
  }

  const handleNext = useCallback(() => {
    if (currentIndexAlbum >= albums.length - 1 || !isModalOpened) {
      return
    }

    switchNextAlbum()

    analytics.push(ANALYTICS_EVENTS.WEB_FLASHBACK_VIEW_NEXT)
  }, [currentIndexAlbum, albums.length, isModalOpened])

  const handlePrev = useCallback(() => {
    if (currentIndexAlbum <= 0 || !isModalOpened) {
      return
    }

    dispatch(photoPreviewFlashbackActions.setIndexCurrentAlbum(currentIndexAlbum - 1))
    dispatch(openPhotoPreviewFlashbackThunk({
      sdkType: SDK_TYPES.DEFAULT,
      type: PREVIEW_TYPES.TIMELINE,
      isViewed: !!albums[currentIndexAlbum - 1].viewed,
      albumName: albums[currentIndexAlbum - 1].description,
      albumId: albums[currentIndexAlbum - 1].id,
    }))
  }, [currentIndexAlbum, albums.length, isModalOpened])

  const handleClose = () => {
    dispatch(timelineActions.setFlashbackAlbums(sortFlashbackAlbums(albums)))
    setBlockClick(false)
    dispatch(photoPreviewFlashbackActions.resetState())
  }

  const startIntervalSecond = () => {
    if (!indexInterval) {
      setSecondTimeOut(0)
    }

    interval = setInterval(() => {
      setSecondTimeOut(prev => prev + 100)
    }, 100)
    setIndexInterval(interval)
  }

  const main = document.getElementById('main')

  useDocumentKeyDown(37, handlePrev)
  useDocumentKeyDown(39, handleNext)
  useDocumentKeyDown(27, handleClose)

  useEffect(() => {
    if (!main) return
    let x
    const minWidthForSwipe = 50
    const handleTouchStart = e => x = e.changedTouches[0].clientX
    const handleTouchEnd = e => {
      if (Math.abs(e.changedTouches[0].clientX - x) > minWidthForSwipe) {
        e.changedTouches[0].clientX < x ? swipeLeft() : swipeRight()
      }
    }
    main.addEventListener('touchstart', handleTouchStart)
    main.addEventListener('touchend', handleTouchEnd)

    function swipeRight() {
      if (currentIndexAlbum !== 0) handlePrev()
    }

    function swipeLeft() {
      if (currentIndexAlbum !== albums.length - 1) handleNext()
    }

    return () => {
      main.removeEventListener('touchstart', handleTouchStart)
      main.removeEventListener('touchend', handleTouchEnd)
    }

  }, [main, items])

  useEffect(() => {
    const title = document.getElementById('title')
    if (title) {
      title.style.animation = 'none'
      setTimeout(function () {
        title.style.animation = 'aniOpacity 2s linear forwards 2s'
      }, 0)
    }
  }, [currentIndexAlbum])

  useEffect(() => {

    if (!currentItem) return

    const arrPrev = items.slice(0, currentIndex)
    const arrNext = items.slice(currentIndex + 1)

    if (arrNext.length > 0) {
      arrNext.map((item) => {
        const line = document.getElementById(item.id)
        line.style.animation = 'none'
      })
    }

    if (arrPrev.length > 0) {
      arrPrev.map((item) => {
        const line = document.getElementById(item.id)
        line.style.animation = 'none'
        setTimeout(function () {
          line.style.animation = 'aniWidth 0s linear forwards'
        }, 0)
      })
    }

    const line = document.getElementById(items[currentIndex].id)

    if (line) {
      line.style.animation = 'none'
      setTimeout(function () {
        line.style.animation = 'aniWidth 5s linear forwards'
      }, 0)
    }
  }, [currentItem])

  const startSlideShow = (delay) => {
    if (currentIndex < 0) {
      dispatch(photoPreviewFlashbackActions.setCurrentItemIndex(0))
    }
    startIntervalSecond()

    if (currentIndex !== totalItemsCount - 1 && totalItemsCount !== 0) {
      timeOut = setTimeout(() => {
        clearInterval(interval)
        dispatch(handleNextPhotoFlashbackThunk())
      }, delay)
      setIndexTimeOut(timeOut)
    } else {
      if (currentIndexAlbum + 1 >= albums.length) {
        timeOut = setTimeout(() => {
          clearInterval(interval)
          handleClose()
        }, delay)
        setIndexTimeOut(timeOut)
      } else {
        timeOut = setTimeout(() => {
          clearInterval(interval)
          handleNext()
        }, delay)
        setIndexTimeOut(timeOut)
      }
    }
  }

  const unBlockClick = () => {
    setTimeout(() => {
      setBlockClick(false)
    }, 200)
  }

  const onSingleClickRight = () => {
    analytics.push(ANALYTICS_EVENTS.WEB_FLASHBACK_NEXT_PHOTO)
    setBlockClick(true)
    unBlockClick()
    if (currentIndex < totalItemsCount - 1) {
      if (!items[currentIndex].id) {
        return handleClose()
      }
      dispatch(handleNextPhotoFlashbackThunk())
    }
  }

  const onSingleClickLeft = () => {
    setBlockClick(true)
    unBlockClick()
    if (currentIndex !== 0) {
      if (!items[currentIndex].id) {
        return handleClose()
      }
      dispatch(handlePrevPhotoFlashbackThunk())
    }
  }

  const onDoubleClick = () => {
    if (!!indexTimeOut) {
      clearInterval(indexInterval)
      clearTimeout(indexTimeOut)
      setIndexTimeOut(null)
      setPauseSlideShow(true)
    } else {
      startSlideShow(defaultDelay - secondTimeOut)
      setPauseSlideShow(false)
    }
  }

  const handleNextPhoto = useClickAndDoubleClickHandler(onSingleClickRight, onDoubleClick)
  const handlePrevPhoto = useClickAndDoubleClickHandler(onSingleClickLeft, onDoubleClick)

  useEffect(() => {
    if (!isModalOpened) return
    startSlideShow(defaultDelay)
    setPauseSlideShow(false)

    return () => {
      clearInterval(interval)
      clearTimeout(timeOut)
      setIndexTimeOut(null)
      setSecondTimeOut(0)
    }
  }, [currentItem])

  const handleError = () => {
    dispatch(photoPreviewFlashbackActions.setLoading(false))
    dispatch(photoPreviewFlashbackActions.setError(true))
  }

  useEffect(() => {
    if (!isModalOpened) {
      dispatch(photoPreviewFlashbackActions.resetState())
    }
  }, [isModalOpened])

  useEffect(() => {
    clearTimeout(loadingTimeoutRef.current)
    setPreviewUrl(null)

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
      abortControllerRef.current = null
    }

    const fetchFileData = async () => {
      dispatch(photoPreviewFlashbackActions.setLoading(true))

      try {
        abortControllerRef.current = new AbortController()

        if (currentItem.type === 'video') {
          const url = await getFileContentUrl(currentItem._links.content.href, abortControllerRef.current)

          setPreviewUrl(url)
        }

        if (currentItem.type === 'image') {
          setPreviewUrl(currentItem._links.image_preview.href)
          if (currentItem._links.image_preview.href === previewUrl) return handleFinishLoading()
        }

        clearTimeout(loadingTimeoutRef.current)

        loadingTimeoutRef.current = setTimeout(() => {
          dispatch(photoPreviewFlashbackActions.setLoading(false))
          dispatch(photoPreviewFlashbackActions.setError(true))
        }, 60000)
      } catch (error) {
        if (error?.name === 'AbortError') {
          return
        }
        dispatch(photoPreviewFlashbackActions.setLoading(false))
        dispatch(photoPreviewFlashbackActions.setError(true))
      }
    }

    if (currentItem) {
      fetchFileData()
    }
  }, [currentItem])

  const handleDownloading = () => {
    const photoSdk = getPhotoSdk()
    const link = currentItem._links.content.href
    if(link) {
      photoSdk.timeline
        .getItemContentByLink(link)
        .then(({ data }) => {
          const url = data?._links?.data?.href
          if(url) {
            window.location.href = url
          }
        }).catch(() => {
          showNotification({
            type: NOTIFICATION_TYPES.WARNING,
            title: t('l_common_wentWrong')
          })
        })
    }
  }

  const prevImageUrl = items[currentIndex - 1]?.type === 'image' ? items[currentIndex - 1]?._links?.image_preview?.href : ''
  const nextImageUrl = items[currentIndex + 1]?.type === 'image' ? items[currentIndex + 1]?._links?.image_preview?.href : ''

  return ReactDOM.createPortal(
    <>
      {
        isModalOpened && (
          <PhotoPreviewModalBox
            id={'main'}
          >
            <SHiddenImg src={prevImageUrl} />

            <SHiddenImg src={nextImageUrl} />

            <TopRowBox
              justifyContent={!!currentItem ? 'space-between' : 'center'}
            >
              {!isMobile && !!currentItem && (
                <NameText>
                  {albumName}
                </NameText>
              )}

              <FullLineBox id={'lineBox'}>
                {items.map((item) => {
                  return (
                    <LineTimeOut
                      key={item.id}
                    >
                      <LineProgress id={item.id} />
                    </LineTimeOut>
                  )
                })}
              </FullLineBox>

              {!!currentItem && (
                <PhotoPreviewFlashbackToolbar
                  handleSlideShow={onDoubleClick}
                  isTimeOut={!!indexTimeOut}
                  type={currentItem.type}
                />
              )}
            </TopRowBox>

            <MainContentBox>
              <ArrowContainer>
                {
                  currentIndexAlbum !== 0 && !isMobile && (
                    <ArrowBox
                      onClick={handlePrev}
                    >
                      <ArrowIcon iconName="arrow_left" />
                    </ArrowBox>
                  )
                }
              </ArrowContainer>

              {albumName && !!currentItem && (
                <TitleText
                  id={'title'}
                >
                  {albumName}
                </TitleText>
              )}

              <ImageBox>
                <LeftPart
                  onClick={handlePrevPhoto}
                  disabled={isBlockClick}
                />

                <RightPart
                  onClick={handleNextPhoto}
                  disabled={isBlockClick}
                />

                {
                  !!currentItem && currentItem.type === 'image' && !!previewUrl && !hasError && (
                    <Img
                      src={previewUrl}
                      onLoad={handleFinishLoading}
                      onError={handleError}
                      fetchPriority="high"
                    />
                  )
                }

                {
                  !!currentItem && currentItem.type === 'video' && !!previewUrl && !hasError && (
                    <Video
                      src={previewUrl + '#t=0.001'}
                      onCanPlay={handleFinishLoading}
                      onError={handleError}
                      isPauseVideo={isPauseSlideShow}
                      key={previewUrl}
                    />
                  )
                }

                {
                  hasError && (
                    <FilePreview>
                      <ContentPlaceholder
                        currentItem={currentItem}
                        onClickDownload={handleDownloading}
                      />
                    </FilePreview>
                  )}

                {(isLoading || isLoadingMore) && <StyledSpinner color='#E1E1E2' />}
              </ImageBox>

              <ArrowContainer className="right">
                {
                  currentIndexAlbum !== albums.length - 1 && !isMobile && (
                    <ArrowBox
                      className="rotated"
                      onClick={handleNext}
                    >
                      <ArrowIcon iconName="arrow_left" />
                    </ArrowBox>
                  )
                }
              </ArrowContainer>
            </MainContentBox>
          </PhotoPreviewModalBox>
        )}

      <PageOverlay
        active={isModalOpened}
        backgroundColor="#030303"
      />
    </>, getModalsRootElement()
  )
}

const Video = ({ onCanPlay, onError, src, isPauseVideo }) => {
  const videoRef = useRef<any>(null)
  const isVideoFirstTimeLoaded = useRef(false)
  const [isLoaded, toggleIsLoaded] = useState(false)

  const handleOnCanPlay = () => {
    onCanPlay()
    videoRef.current.play()
    isVideoFirstTimeLoaded.current = true
    toggleIsLoaded(true)
  }

  const handleStop = () => {
    videoRef.current.pause()
  }

  const handlePlay = () => {
    videoRef.current.play()
  }

  useEffect(() => {
    if (videoRef.current && isVideoFirstTimeLoaded.current) {
      if (isPauseVideo) {
        handleStop()
      }
      if (!isPauseVideo) {
        handlePlay()
      }
    }
  }, [isPauseVideo])

  useLayoutEffect(() => {
    videoRef.current.src = src

    return () => {
      videoRef.current.src = ''
    }
  }, [src])

  return (
    <StyledVideo
      onCanPlay={handleOnCanPlay}
      onError={onError}
      controls
      preload="auto"
      ref={videoRef}
      className={classNames({ 'not-loaded': !isLoaded })}
    />
  )
}

const FullLineBox = styled.div`
  height: 2px;
  width: 536px;
  z-index: 5;
  display: flex;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    width: calc(100% - 20px);
    margin: 10px 10px;
  }
`

const LineTimeOut = styled.div`
  height: 2px;
  width: 100%;
  z-index: 6;
  background-color: grey;
  margin-right: 2px;

  :last-child {
    margin-right: 0;
  }
`

const LineProgress = styled.div`
  width: 0;
  height: 2px;
  z-index: 7;
  background-color: var(--tag-text);

  @keyframes aniWidth {
    100% {
      width: 100%;
    }
    0% {
      width: 0;
    }
  }
`

const partStyle = css`
  position: absolute;
  bottom: 0;
  width: calc(50%);
  height: 100%;
  opacity: 0;
  z-index: 2;
  -webkit-tap-highlight-color: var(--divider-primary);
`

const LeftPart = styled.button`
  left: 0;
  ${partStyle}
`

const RightPart = styled.button`
  right: 0;
  ${partStyle}
`

const NameText = styled.div`
  width: 100px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: var(--tag-text);
  margin-left: 16px;
  max-width: 300px;
  overflow: hidden;
`

const TitleText = styled.div`
  color: var(--tag-text);
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) -0.28%, rgba(0, 0, 0, 0) 95.45%);  
  z-index: 4;
  animation: aniOpacity 2s linear forwards 2s;

  @keyframes aniOpacity {
    100% {
      opacity: 0;
    }
    0% {
      opacity: 1;
    }
  }
`

const PhotoPreviewModalBox = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
`

const TopRowBox = styled(TopRow)`
  justify-content: ${props => props.justifyContent} !important;

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 0;
    z-index: 3;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) -0.28%, rgba(0, 0, 0, 0.5) 95.45%);
  }
`

const MainContentBox = styled(MainContent)`
  height: calc(100% - 120px);

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.TABLET}) {
    height: 100%;
  }
`

const ArrowContainer = styled.div`
  width: 48px;
  display: flex;
  align-items: center;
  z-index: 3;
  position: absolute;
  height: fit-content;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);

  &.right {
    left: unset;
    right: 20px;
  }

  @media (max-width: 767px) {
    left: 10px;

    &.right {
      left: unset;
      right: 10px;
    }
  }
`
