import { useAppSelector } from "store"

export const getMobileLeftMenuStatusSelector = () => useAppSelector(state => state.app.leftMenuOpenedOnMobile)

export const getMobileRightMenuStatusSelector = () => useAppSelector(state => state.app.rightMenuOpenedOnMobile)

export const getContentTypesUploadSelector = () => useAppSelector(state => state.app.contentTypesUpload)

export const getContentTypesDropzoneSelector = () => useAppSelector(state => state.app.contentTypesDropzone)

export const getAvailableExtensionsForUploading = () => useAppSelector(state => state.app.extensionAvailableForUploading)

export const getLayoutContentOverflowSelector = () => useAppSelector(state => state.app.noLayoutContentOverflow)

export const getFeedbackModalVisibilitySelector = () => useAppSelector(state => state.app.isFeedbackModalVisibility)

export const getWSStateSelector = () => useAppSelector(state => state.app.webSocketState)

export const getIsRightSidebarTransparentSelector = () => useAppSelector(state => state.app.isRightSidebarTransparent)

export const getIsMobileMenuVisible = () => useAppSelector(state => state.app.isMobileMenuVisible)

export const getAreLayoutSidebarsVisible = () => useAppSelector(state => state.app.areLayoutSidebarsVisible)
