import { LOADING_STATUSES } from 'constants/loadingStatuses'

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { request } from 'api/request'
import { NavigateFunction } from 'react-router-dom'
import { hideGlobalProgressLoader, showGlobalProgressLoader } from 'features/common/app-progress-bar'
import { RootState } from 'store'
import { NOTIFICATION_TYPES, showNotification } from 'features/common/notifications'
import { t } from 'i18next'
import { initFilesPublicLinkSocket } from 'sdk/publicLinkSocket'
import { WebSocketService } from 'sdk/webSocketService'
import { getFilesSdk } from 'sdk/files'
import { IFsNodeSchema, IGetFsNodesSchema, NodeTypes } from '@cloudike/web_files'
import _ from 'lodash'
import { OrderType, SelectType } from '@cloudike/web_ui_components'
import { FileItemsTypes } from 'features/files/types'
import { getNewFileName, getNewFolderName, isFileExist, isFolderExist } from 'features/files/filesUtils'
import { uploadSharedFilesThunk } from 'features/common/files-uploading/filesUploadingSlice'
import pathsToTree from 'paths-to-tree-structure'

import {
  selectAllFileListItemsReducer,
  selectFileListItemReducer,
  selectFileListItemWithPressedCtrlReducer,
  selectFileListItemWithPressedShiftReducer,
  unselectAllFileListItemsReducer
} from "../../utils/filesListSelection"
import { initFilesCacheService } from "../files/filesCacheService"

export enum FilesPublicLinksErrorTypes {
  NOT_EXIST = 'NOT_EXIST',
  EMPTY_FOLDER = 'EMPTY_FOLDER',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  NO_ACCESS = 'NO_ACCESS',
}

export enum SortColumns {
  NAME = 'name',
  MODIFIED = 'updated',
  SIZE = 'file_info.size',
}

export enum ReplaceModalTypes {
  REPLACE = 'replace',
  KEEP_BOTH = 'keep_both'
}

export enum CheckboxVisibilityType {
  VISIBLE = 'visible',
  HIDDEN = 'hidden'
}

interface State {
  itemsLoadingStatus: LOADING_STATUSES,
  loadingMoreStatus: LOADING_STATUSES,
  totalItemsCount: number,
  passwordModalOpened: boolean,
  error: null | FilesPublicLinksErrorTypes
  nodeData: null | IFsNodeSchema,
  rootNodeType: NodeTypes,
  notMyResourseHref: string,
  itemsHref: string,
  idsHref: string,
  nodeHref: string,
  copyDirHref: string,
  token: null | string,
  sharedId: null | string,
  permission: null | string,
  isSizeQuotaExceedError: boolean,

  breadcrumbs: { name: string, id?: string }[],
  sort: { by: SortColumns.NAME, direction: OrderType.ASK },
  selectedItemsIds: string[],
  renamingItemId: string | null,
  currentFolder: null,
  currentFolderId: string,
  selectType: SelectType,
  lastSelectedIndex: number | null,
  lastSelectedWithShiftIndex: number | null,
  checkboxVisibility: CheckboxVisibilityType,
  replaceFilesNodeModalData: {
    opened?: boolean,
    nodeType?: FileItemsTypes,
    name?: string,
    type?: ReplaceModalTypes
  }
}

const adapter = createEntityAdapter<IFsNodeSchema>()

const MAP_FOLDER_NAME_ID_KEY = 'MAP_FOLDER_NAME_ID'

const cacheService = initFilesCacheService()

export const publicLinkSelectors = adapter.getSelectors()

let tempFilesWaitingForUserDecision: File[]
let callbackAfterUserDecision: () => void

const sortNodes = (nodes, { by, direction }) => {
  const orderByType = (entity) => entity.type

  return _.chain(nodes)
    .orderBy(by === 'name' ? node => node.name.toUpperCase() : by, [direction])
    .orderBy(orderByType)
    .value()
}

let publicLinkSocket: WebSocketService

export const fetchFilesPublicLinkItems = createAsyncThunk(
  'publicLinkFiles/fetchFilesPublicLinkItems',
  async function ({ id }: { id?: string }, { dispatch, getState }) {
    const state = getState() as RootState
    const href = state.publicLinkFiles.itemsHref
    const token = state.publicLinkFiles.token
    const shareId = state.publicLinkFiles.sharedId
    const sdk = getFilesSdk()

    const params: IGetFsNodesSchema = {
      preview: true,
      preview_jwt: true,
      embedded: true
    }

    if (id) {
      params.parent_id = id
    }

    const nodesResponse = !!id ?
      await sdk.publicLinksService.getAllNotMyFsNodesByLink(href, params, null, null, token) :
      await sdk.publicLinksService.getAllNotMyFsRootDirContent(shareId, params, null, null, token)

    const items = nodesResponse

    dispatch(actions.setItems(items))
    cacheService.setCachedFiles(id, items)
  }
)

export const subscribeFilesPublicLinkToWsThunk = createAsyncThunk(
  'publicLinkFiles/subscribeFilesPublicLinkToWsThunk',
  async function ({ id, jwtToken }: { id: string, jwtToken?: string }, { getState, dispatch }) {
    publicLinkSocket = initFilesPublicLinkSocket({ id, jwtToken })

    publicLinkSocket.addEventListener('default', ({ output }) => {
      const state = getState() as RootState
      const { publicLinkFiles: { currentFolderId, sharedId } } = state
      const changes = output?.changes || []
      const currentFilesItems = publicLinkSelectors.selectAll(state.publicLinkFiles)
      const rootNodeType = state.publicLinkFiles.rootNodeType
      const nodeData = state.publicLinkFiles.nodeData

      const parentId = currentFolderId === sharedId ? '' : currentFolderId

      changes.forEach(change => {
        if (change.action === 'created') {
          if (change.parent_id !== parentId) {
            return
          }

          const node = {
            created: change.created,
            updated: change.updated,
            name: change.name,
            id: change.node_id,
            type: change.type,
            parent_id: change.parent_id,
            is_trashed: false,
            is_explicitly_trashed: false,
            is_shared: false,
            file_info: change?.fields?.file_info,
            _embedded: change._embedded,
            _links: change._links
          }

          const existedNode = currentFilesItems.find(n => n.id === node.id)

          if (existedNode) {
            dispatch(actions.deleteItems([existedNode.id]))
          }

          dispatch(actions.setAll([node, ...currentFilesItems]))
          cacheService.setCachedFiles(change.parent_id, [node, ...currentFilesItems])
        }

        if (change.action === 'changed') {
          if ((change.parent_id !== (state.publicLinkFiles.currentFolderId || '')) && (output.share_id !== state.publicLinkFiles.currentFolderId)) {
            return
          }

          const existedNode = currentFilesItems.find(n => n.id === change.node_id)

          const node = {
            created: change.created,
            updated: change.updated,
            name: change.name,
            id: change.node_id,
            type: change.type,
            parent_id: change.parent_id,
            is_trashed: false,
            is_explicitly_trashed: false,
            is_shared: false,
            _embedded: change._embedded || (existedNode as any)?._embedded,
            ...(change?.fields ? change.fields : {})
          }

          if (existedNode) {
            if (rootNodeType === NodeTypes.FILE) {
              dispatch(actions.setNodeData({ ...nodeData, ...(change?.fields ? change.fields : {}) }))
            }

            if (change?.fields?.is_trashed) {
              dispatch(actions.deleteItems([existedNode.id]))
              cacheService.deleteFile(change.parent_id, existedNode.id)

              return
            }
          }

          dispatch(actions.updateItem(node))
          cacheService.updateFile(change.parent_id, node)
        }
      })
    })
  }
)

export const unsubscribeFilesPublicLinkToWsThunk = createAsyncThunk(
  'publicLinkFiles/unsubscribeFilesPublicLinkToWsThunk',
  async function () {
    publicLinkSocket?.closeConnection()
  }
)

export const fetchFilesPublicLinkBreadcrumbsThunk = createAsyncThunk(
  'publicLinkFiles/fetchFilesPublicLinkBreadcrumbsThunk',
  async function ({ paths }: { paths: string[] }, { getState, dispatch } ) {
    const state = getState() as RootState
    const nodeHref = state.publicLinkFiles.nodeHref
    const catalogId = paths.slice(-1)[0]

    const nameIdMapFromStorage = JSON.parse(sessionStorage.getItem(MAP_FOLDER_NAME_ID_KEY)) || {}

    const breadcrumbs = []

    for (const path of paths.slice(1)) {
      let name = nameIdMapFromStorage[path]

      if (!name) {
        const response = await (getFilesSdk().publicLinksService.getNotMyFsNodeByLink(nodeHref, path))

        name = response.data.name
      }

      breadcrumbs.push({
        name,
        id: path
      })
    }

    const filteredBreadcrumbs = breadcrumbs.filter(path => !!path.name)

    if (filteredBreadcrumbs.length) {
      dispatch(actions.setBreadcrumbs([{ name: '' }, ...filteredBreadcrumbs]))
    } else {
      dispatch(actions.setBreadcrumbs([{ name: '' }]))
    }

    dispatch(actions.setCurrentFolderId(catalogId))
  }
)

export const fetchFilesPublicLinkInfoThunk = createAsyncThunk(
  'publicLinkFiles/fetchFilesPublicLinkInfoThunk',
  async function ({ id, navigate }: { id: string, navigate: NavigateFunction }, { dispatch, getState }) {
    try {
      const state = getState() as RootState
      const publicLinksService = getFilesSdk().publicLinksService
      const userId = state.user.userData.id

      dispatch(actions.setSharedId(id))

      const response = await publicLinksService.getPublicShare(id, userId)
      const data = response.data

      const myResource = data?._links?.my_resource?.href
      const notMyResource = data?._links?.not_my_resource?.href
      const accessType = data.access_type

      if (myResource) {
        const myResourceResponse = await request('GET', myResource, {}, { host: null })

        navigate(`/drive/${myResourceResponse.id}`, { state: { needToCheckIdsPath: true, nodeType: myResourceResponse.type } })

        return
      }

      if (notMyResource) {
        const notMyResourceResponse = await request('GET', notMyResource, {}, { host: null })

        dispatch(actions.setNotMyResourceHref(notMyResource))
        dispatch(actions.setPermission(notMyResourceResponse?._embedded?.share?.permission))
        dispatch(actions.setNodeData(notMyResourceResponse))
        dispatch(actions.setRootNodeType(notMyResourceResponse.type))

        const response = await getFilesSdk().publicLinksService.getShareWithMeRootById(id, { preview: true, preview_jwt: true })

        const href = response.data._links.nodes.href
        const nodeHref = response.data._links.node.href
        const idsHref = response.data._links.ids.href
        const copyItemsHref = response.data._links.dir_copy_tasks.href

        if (notMyResourceResponse.type === NodeTypes.FILE) {
          dispatch(actions.setNodeData(response.data))
        }

        dispatch(actions.setItemsHref(href))
        dispatch(actions.setItemsIdsHref(idsHref))
        dispatch(actions.setNodeHref(nodeHref))
        dispatch(actions.setCopyDirHref(copyItemsHref))

        dispatch(subscribeFilesPublicLinkToWsThunk({ id }))

        return
      }

      if (accessType === 'password') {
        const tokensLink = data?._links?.tokens?.href

        if (!tokensLink) {
          dispatch(actions.setError(FilesPublicLinksErrorTypes.SOMETHING_WENT_WRONG))

          return
        }

        dispatch(actions.togglePasswordModal(true))

        return
      }

      dispatch(actions.setError(FilesPublicLinksErrorTypes.NO_ACCESS))
    } catch (error) {
      if (error.code === 'NotFound') {
        dispatch(actions.setError(FilesPublicLinksErrorTypes.NOT_EXIST))

        return
      }

      dispatch(actions.setError(FilesPublicLinksErrorTypes.SOMETHING_WENT_WRONG))
    }
  }
)
export const submitPasswordForSharedFiles = createAsyncThunk(
  'publicLinkFiles/submitPasswordForSharedFiles',
  async function ({ password, errorCallback }: { password: string, errorCallback: (error: any) => void }, { getState, dispatch }) {
    try {
      const publicLinksService = getFilesSdk().publicLinksService
      showGlobalProgressLoader()

      const id = (getState() as RootState).publicLinkFiles.sharedId

      const { data: { token } } = await publicLinksService.createShareToken(id, password)

      dispatch(actions.setToken(token))
      const response = await getFilesSdk().publicLinksService.getShareWithMeRootById(id, { preview: true, preview_jwt: true }, token)

      const href = response.data._links.nodes.href
      const idsHref = response.data._links.ids.href
      const nodeHref = response.data._links.node.href
      const copyItemsHref = response.data._links.dir_copy_tasks.href

      dispatch(actions.setItemsIdsHref(idsHref))
      dispatch(actions.setItemsHref(href))
      dispatch(actions.setPermission(response?.data._embedded?.share?.permission))
      dispatch(actions.setNodeData(response?.data))
      dispatch(actions.setRootNodeType(response?.data?.type))
      dispatch(actions.setNodeHref(nodeHref))
      dispatch(actions.setCopyDirHref(copyItemsHref))

      dispatch(fetchFilesPublicLinkItems({}))

      dispatch(actions.togglePasswordModal(false))

      dispatch(subscribeFilesPublicLinkToWsThunk({ id, jwtToken: token }))
    } catch (error) {
      errorCallback(error)
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const downloadSharedFilesArchiveThunk = createAsyncThunk(
  'publicLinkFiles/downloadSharedFilesArchiveThunk',
  async function ({ ids: selectedItemsIds }: { ids?: string[] }, { getState }) {
    showGlobalProgressLoader()
    try {
      const state = (getState() as RootState).publicLinkFiles
      const nodeData = state.nodeData as any
      const nodesDownloadsHref = nodeData._links.nodes_downloads.href
      const currentItems = publicLinkSelectors.selectAll(state)
      const isAnyItemsSelected = selectedItemsIds && selectedItemsIds.length > 0
      const allItemsIds = currentItems.map(item => item.id)
      const itemsToDownloadIds = isAnyItemsSelected ? selectedItemsIds : allItemsIds
      const token = state.token
      const shareId = state.sharedId
      const rootNodeType = state.rootNodeType

      if (itemsToDownloadIds.length === 1) {
        const [firstItemId] = itemsToDownloadIds
        const itemToDownload = currentItems.find(item => item.id === firstItemId)
        await downloadSingleFileOrFolder(shareId, rootNodeType, itemToDownload, nodesDownloadsHref, token)
      } else {
        await downloadMultipleItemsAsZip(token, itemsToDownloadIds, nodesDownloadsHref)
      }
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }

    async function downloadSingleFileOrFolder(
      shareId: string,
      rootNodeType: NodeTypes,
      itemToDownload: IFsNodeSchema,
      nodesDownloadsHref: string,
      token: string) {
      const downloadLink = await getDownloadLink(shareId, itemToDownload, nodesDownloadsHref, token)
      if(downloadLink) {
        window.location.href = downloadLink
      }
    }


    async function getDownloadLink(
      shareId: string,
      itemToDownload: IFsNodeSchema,
      nodesDownloadsHref: string,
      token: string
    ): Promise<string> {
      const nodeType = itemToDownload.type
      const filesSdk = getFilesSdk().publicLinksService
      const { id: itemId } = itemToDownload

      if (nodeType === NodeTypes.FILE) {
        return filesSdk.getDownloadLink(shareId, itemId, token)
      }

      if (nodeType === NodeTypes.DIR) {
        const { data: { _links: { zip_stream: { href } } } } = await filesSdk
          .createSharedWithMeNodesZipStream(nodesDownloadsHref, [itemId], token)
        return href
      }

    }

    async function downloadMultipleItemsAsZip(token: string, itemsToDownloadIds: string[], nodesDownloadsHref: string) {
      const { data: { _links: { zip_stream: { href } } } } =
        await getFilesSdk()
          .publicLinksService
          .createSharedWithMeNodesZipStream(nodesDownloadsHref, itemsToDownloadIds, token)
      window.location.href = href
    }
  }
)



export const createFilesPublicLinkFolderThunk = createAsyncThunk(
  'publicLinkFiles/createFilesPublicLinkFolderThunk',
  async ({ name, parentId }: { name: string, parentId: string }, { getState, dispatch }) => {
    const state = getState() as RootState

    const nodesHref = state.publicLinkFiles.itemsHref
    const idsHref = state.publicLinkFiles.idsHref
    const token = state.publicLinkFiles.token

    showGlobalProgressLoader()

    try {
      const sdk = getFilesSdk()

      if (isFolderExist(publicLinkSelectors.selectAll(state.publicLinkFiles), name)) {
        showNotification({
          type: NOTIFICATION_TYPES.WARNING,
          title: t('l_notification_folderNameError'),
          message: t('l_notification_createFolderError')
        })

        return
      }

      let newFolderName = name

      if (isFileExist(publicLinkSelectors.selectAll(state.publicLinkFiles), name)) {
        const currentFilesItems = publicLinkSelectors.selectAll(state.publicLinkFiles)

        newFolderName = getNewFileName(currentFilesItems, name)
      }

      const result = await sdk.publicLinksService.createNotMyFsNodeByLink(nodesHref, idsHref,
        { type: NodeTypes.DIR, name: newFolderName, parent_id: parentId },
        token
      )

      dispatch(actions.updateItem({ id: result.data.id, _links: result.data._links }))
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const uploadSharedFolderThunk = createAsyncThunk(
  'publicLinkFiles/uploadSharedFolderThunk',
  async ({ files, callback }: { files: File[], callback?: () => void }, { getState, dispatch }) => {
    const state = getState() as RootState
    const { idsHref, currentFolderId, itemsHref } = state.publicLinkFiles
    const nodeHref = state.publicLinkFiles.nodeHref
    const sharedId = state.publicLinkFiles.sharedId
    const catalogId = currentFolderId === sharedId ? '' : currentFolderId
    const token = state.publicLinkFiles.token

    const replaceModalData = state.publicLinkFiles.replaceFilesNodeModalData

    const sdk = getFilesSdk()

    showGlobalProgressLoader()

    try {
      const paths = Array.from(files).map((file) => file.webkitRelativePath)

      const parentIdToFilesMap = {}

      const generateFolderStructure = async (parentId, items, path, previousFolderName = '') => {
        for (const item of items) {
          if (item.type === FileItemsTypes.DIR) {
            const newDir = await sdk.publicLinksService.createNotMyFsNodeByLink(itemsHref, idsHref,
              { type: NodeTypes.DIR, name: item.name, parent_id: parentId },
              token
            )

            const newDirId = newDir.data.id

            if (item.children) {
              parentIdToFilesMap[newDirId] = []
              await generateFolderStructure(newDirId, item.children, path + (previousFolderName || item.name) + '/')
            }
          } else {
            const file = Array.from(files).find(file => file.webkitRelativePath === path + item.name)

            parentIdToFilesMap[parentId].push(file)
          }
        }
      }

      const items = pathsToTree(paths).items

      const rootFolderName = items[0].name
      const currentFilesItems = publicLinkSelectors.selectAll(state.files)

      if (replaceModalData.name === rootFolderName && replaceModalData.nodeType === FileItemsTypes.DIR) {
        if (replaceModalData.type === ReplaceModalTypes.KEEP_BOTH) {
          items[0].name = getNewFolderName(currentFilesItems, rootFolderName)
        }

        if (replaceModalData.type === ReplaceModalTypes.REPLACE) {
          const folderToRemove = currentFilesItems.find(item => item.name === rootFolderName && item.type === NodeTypes.DIR)

          await sdk.publicLinksService.updateNotMyFsNodeByLink(nodeHref, folderToRemove.id, { is_trashed: true }, token)
        }

        dispatch(actions.resetReplaceFilesNodeModalData())
        tempFilesWaitingForUserDecision = []
      } else {
        if (isFolderExist(publicLinkSelectors.selectAll(state.files), rootFolderName)) {
          tempFilesWaitingForUserDecision = files
          callbackAfterUserDecision = callback

          dispatch(actions.setReplaceFilesNodeModalData({ nodeType: FileItemsTypes.DIR, name: rootFolderName, opened: true }))

          return
        }
      }

      await generateFolderStructure(catalogId, items, '', rootFolderName !== items[0].name ? rootFolderName : undefined)

      Object.keys(parentIdToFilesMap).forEach(key => {
        const files = parentIdToFilesMap[key]

        dispatch(uploadSharedFilesThunk({ files, parentId: key, sharedId, idsUrl: idsHref, token }))
      })

      if (callback) {
        callback()
      }
    } catch (error) {
      console.log(error)
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const checkAndUploadSharedFilesThunk = createAsyncThunk(
  'publicLinkFiles/checkAndUploadSharedFilesThunk',
  async ({ files, callback }: { files: FileList, callback?: () => void }, { getState, dispatch }) => {
    const state = getState() as RootState
    const { idsHref, currentFolderId } = state.publicLinkFiles
    const sharedId = state.publicLinkFiles.sharedId
    const catalogId = currentFolderId === sharedId ? '' : currentFolderId
    const token = state.publicLinkFiles.token

    const replaceModalData = state.publicLinkFiles.replaceFilesNodeModalData
    const currentFilesItems = publicLinkSelectors.selectAll(state.publicLinkFiles)

    showGlobalProgressLoader()

    try {
      if (files.length > 1) {
        const filesToUpload = Array.from(files).map(file => {
          if (isFileExist(currentFilesItems, file.name)) {
            const newFileName = getNewFileName(currentFilesItems, file.name)

            const blob = file.slice(0, file.size, file.type)

            const newFile = new File([blob], newFileName, { type: file.type })

            return newFile
          }

          return file
        })

        dispatch(uploadSharedFilesThunk({ files: filesToUpload as any, callback, parentId: catalogId, sharedId, idsUrl: idsHref, token }))

        return
      }

      const fileName = files[0].name

      if (replaceModalData.name === fileName && replaceModalData.nodeType === FileItemsTypes.FILE) {
        if (replaceModalData.type === ReplaceModalTypes.KEEP_BOTH) {
          const newFileName = getNewFileName(currentFilesItems, fileName)
          const file = files[0]

          const blob = file.slice(0, file.size, file.type)

          const newFile = new File([blob], newFileName, { type: file.type })

          dispatch(uploadSharedFilesThunk({ files: [newFile] as any, callback, parentId: catalogId, sharedId, idsUrl: idsHref, token }))
          dispatch(actions.setReplaceFilesNodeModalData({ name: null }))

          return
        }

        if (replaceModalData.type === ReplaceModalTypes.REPLACE) {
          const fileToReplace = currentFilesItems.find(item => item.name === fileName && item.type === NodeTypes.FILE)

          dispatch(uploadSharedFilesThunk({ files, callback, parentId: catalogId, modificators: [{ target_node_id: fileToReplace.id }], sharedId, idsUrl: idsHref, token }))
        }

        dispatch(actions.setReplaceFilesNodeModalData({ name: null }))
        tempFilesWaitingForUserDecision = []
      } else {
        if (isFileExist(currentFilesItems, fileName)) {
          tempFilesWaitingForUserDecision = Array.from(files)

          dispatch(actions.setReplaceFilesNodeModalData({ nodeType: FileItemsTypes.FILE, name: fileName, opened: true }))

          if (callback) {
            callback()
          }
          return
        } else {
          dispatch(uploadSharedFilesThunk({ files, callback, parentId: catalogId, sharedId, idsUrl: idsHref, token }))
        }
      }
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  })

export const keepBothSharedFileVersionsThunk = createAsyncThunk(
  'publicLinkFiles/keepBothSharedFileVersionsThunk',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState

    const replaceModalData = state.publicLinkFiles.replaceFilesNodeModalData

    if (replaceModalData.nodeType === FileItemsTypes.DIR) {
      dispatch(uploadSharedFolderThunk({ files: [...tempFilesWaitingForUserDecision] }))
    }

    if (replaceModalData.nodeType === FileItemsTypes.FILE) {
      dispatch(checkAndUploadSharedFilesThunk({ files: [...tempFilesWaitingForUserDecision] as any }))
    }

    dispatch(actions.resetReplaceFilesNodeModalData())

    if (callbackAfterUserDecision) {
      callbackAfterUserDecision()

      callbackAfterUserDecision = undefined
    }
  }
)

export const replaceSharedFileThunk = createAsyncThunk(
  'publicLinkFiles/replaceSharedFileThunk',
  async (_, { getState, dispatch }) => {
    const state = getState() as RootState

    const replaceModalData = state.publicLinkFiles.replaceFilesNodeModalData

    if (replaceModalData.nodeType === FileItemsTypes.DIR) {
      dispatch(uploadSharedFolderThunk({ files: [...tempFilesWaitingForUserDecision] }))
    }

    if (replaceModalData.nodeType === FileItemsTypes.FILE) {
      dispatch(checkAndUploadSharedFilesThunk({ files: [...tempFilesWaitingForUserDecision] as any }))
    }

    dispatch(actions.resetReplaceFilesNodeModalData())

    if (callbackAfterUserDecision) {
      callbackAfterUserDecision()

      callbackAfterUserDecision = undefined
    }
  }
)

export const cancelSharedFileReplacingThunk = createAsyncThunk(
  'publicLinkFiles/cancelSharedFileReplacingThunk',
  async (_, { dispatch }) => {
    tempFilesWaitingForUserDecision = []
    dispatch(actions.resetReplaceFilesNodeModalData())
  }
)

export const renameSharedFileNodeThunk = createAsyncThunk(
  'publicLinkFiles/renameSharedFileNodeThunk',
  async ({ name }: { name: string }, { getState, dispatch }) => {
    const state = getState() as RootState

    const nodeHref = state.publicLinkFiles.nodeHref

    const nodes = publicLinkSelectors.selectAll(state.publicLinkFiles)
    const renamingItemId = state.publicLinkFiles.renamingItemId
    const node = nodes.find(node => node.id === renamingItemId)
    const nodeType = node?.type
    const token = state.publicLinkFiles.token
    const rootNodeType = state.publicLinkFiles.rootNodeType

    let finalName = name

    if (name === node.name) {
      dispatch(actions.setRenamingItemId(null))
      dispatch(actions.unselectAll())

      return
    }

    showGlobalProgressLoader()

    try {
      const sdk = getFilesSdk()

      if (nodeType !== NodeTypes.DIR && isFileExist(nodes, name)) {
        finalName = getNewFileName(nodes, name)
      }

      if (nodeType === NodeTypes.DIR && isFolderExist(nodes, name)) {
        finalName = getNewFolderName(nodes, name)
      }

      await sdk.publicLinksService.updateNotMyFsNodeByLink(nodeHref, node.id, { name: finalName }, token)

      dispatch(actions.setRenamingItemId(null))
      dispatch(actions.unselectAll())

      if (nodeType === NodeTypes.DIR) {
        showNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          title: t('l_notification_folderRenamed')
        })
      } else {
        showNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          title: t('l_notification_fileRenamed')
        })
      }
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const deleteFilesPublicLinkNodesThunk = createAsyncThunk(
  'publicLinkFiles/deleteFilesPublicLinkNodesThunk',
  async ({ ids }: { ids: string[] }, { getState, dispatch }) => {
    showGlobalProgressLoader()
    const state = getState() as RootState

    const nodeHref = state.publicLinkFiles.nodeHref
    const token = state.publicLinkFiles.token

    try {
      const sdk = getFilesSdk()

      await Promise.all(ids.map(async id => {
        sdk.publicLinksService.updateNotMyFsNodeByLink(nodeHref, id, { is_trashed: true }, token)
        dispatch(actions.deleteItems([id]))
      }))

      dispatch(actions.unselectAll())

      showNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        title: t('l_notification_itemsDeletedforWeb', { number: ids.length })
      })
    } catch (error) {
      showNotification({
        type: NOTIFICATION_TYPES.WARNING,
        title: t('l_notification_somethingWrongTryAgain')
      })
    } finally {
      hideGlobalProgressLoader()
    }
  }
)

export const publicLinkFilesSlice = createSlice({
  name: 'publicLinkFiles',
  initialState: adapter.getInitialState<State>({
    itemsLoadingStatus: LOADING_STATUSES.LOADING,
    loadingMoreStatus: LOADING_STATUSES.IDLE,
    totalItemsCount: 0,
    passwordModalOpened: false,
    error: null,
    nodeData: null,
    rootNodeType: NodeTypes.DIR,
    token: null,
    sharedId: null,
    permission: null,
    notMyResourseHref: null,
    itemsHref: null,
    idsHref: null,
    nodeHref: null,
    copyDirHref: null,
    isSizeQuotaExceedError: null,

    breadcrumbs: [],
    sort: { by: SortColumns.NAME, direction: OrderType.ASK },
    selectedItemsIds: [],
    renamingItemId: null,
    currentFolder: null,
    currentFolderId: '',
    selectType: SelectType.NONE,
    lastSelectedIndex: null,
    lastSelectedWithShiftIndex: null,
    checkboxVisibility: CheckboxVisibilityType.HIDDEN,
    replaceFilesNodeModalData: {
      opened: false,
      nodeType: FileItemsTypes.DIR,
      name: null,
      type: ReplaceModalTypes.KEEP_BOTH
    }
  }),
  reducers: {
    setItemsLoadingStatus: (state, action) => {
      state.itemsLoadingStatus = action.payload
    },
    togglePasswordModal: (state, action) => {
      state.passwordModalOpened = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setIsSizeQuotaExceedError: (state, action) => {
      state.isSizeQuotaExceedError = action.payload
    },
    setItems: (state, action) => {
      adapter.setAll(state, sortNodes(action.payload, state.sort))
      state.itemsLoadingStatus = LOADING_STATUSES.SUCCEEDED
    },
    setTotalCount: (state, action) => {
      state.totalItemsCount = action.payload
    },
    setNodeData: (state, action) => {
      state.nodeData = action.payload
    },
    setRootNodeType: (state, action) => {
      state.rootNodeType = action.payload
    },
    removeItems: (state, action) => {
      adapter.removeMany(state, action.payload)
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setSharedId: (state, action) => {
      state.sharedId = action.payload
    },
    setPermission: (state, action) => {
      state.permission = action.payload
    },
    setNotMyResourceHref: (state, action) => {
      state.notMyResourseHref = action.payload
    },
    setItemsHref: (state, action) => {
      state.itemsHref = action.payload
    },
    setItemsIdsHref: (state, action) => {
      state.idsHref = action.payload
    },
    setNodeHref: (state, action) => {
      state.nodeHref = action.payload
    },
    setCopyDirHref: (state, action) => {
      state.copyDirHref = action.payload
    },

    addItem: (state, action) => {
      adapter.removeOne(state, action.payload.id)
      adapter.addOne(state, action.payload)
    },
    updateItem: (state, action) => {
      adapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    },
    setAll: (state, action) => {
      const nodes = sortNodes(action.payload, state.sort)

      adapter.setAll(state, nodes)
    },
    selectItem: selectFileListItemReducer(publicLinkSelectors),
    selectItemWithPressedCtrl: selectFileListItemWithPressedCtrlReducer(publicLinkSelectors),
    selectItemWithPressedShift: selectFileListItemWithPressedShiftReducer(publicLinkSelectors),
    selectAll: selectAllFileListItemsReducer,
    unselectAll: unselectAllFileListItemsReducer,
    setSort: (state, action) => {
      state.sort = action.payload

      const nodes = sortNodes(publicLinkSelectors.selectAll(state), state.sort)

      adapter.setAll(state, nodes)
    },
    setCurrentFolder: (state, action) => {
      state.currentFolder = action.payload
    },
    setCurrentFolderId: (state, action) => {
      state.currentFolderId = action.payload
    },
    deleteItems: (state, action) => {
      adapter.removeMany(state, action.payload)
    },
    setRenamingItemId: (state, action) => {
      state.renamingItemId = action.payload
    },
    setReplaceFilesNodeModalData: (state, action) => {
      state.replaceFilesNodeModalData = { ...state.replaceFilesNodeModalData, ...action.payload }
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload
    },
    resetReplaceFilesNodeModalData: (state) => {
      state.replaceFilesNodeModalData = {
        opened: false,
        nodeType: FileItemsTypes.DIR,
        name: null,
        type: ReplaceModalTypes.KEEP_BOTH
      }
    },
  }
})

const { reducer, actions } = publicLinkFilesSlice

export { reducer as publicLinkFilesReducer, actions as publicLinkFilesActions }
