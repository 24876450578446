import React from "react"

import styled from 'styled-components'
import { STYLED_VARIABLES } from "@cloudike/web_ui_components"

import { getUiTheme } from "../user/selectors"
import { formatImgUrl } from "../../constants/configs/theme.config"
import { THEME } from "../user/userSlice"

interface EmptyFamilyMembersPlaceholderProps {
  className?: string,
  texts: {
    title: string,
    description: string,
    inviteBtn: string
  },
  onInviteFamilyMember: () => void
}

export const EmptyFamilyMembersPlaceholder: React.FC<EmptyFamilyMembersPlaceholderProps> = ({
  className = '',
  texts,
  onInviteFamilyMember
}) => {
  const theme = getUiTheme()
  return (
    <EmptyFamilyMembersPlaceholderBox
      className={className}
    >
      {theme === THEME.LIGHT ? <ContainerImg src={formatImgUrl("/assets/placeholders-images/empty-family-members.svg")} /> :
        <ContainerImg src={formatImgUrl("/assets/placeholders-images-black/empty-family-members.svg")} />}

      <EmptyTitle>
        {texts.title}
      </EmptyTitle>

      <EmptyText>
        {texts.description}
      </EmptyText>

      <StyledSecondaryButton
        onClick={onInviteFamilyMember}
      >
        {texts.inviteBtn}
      </StyledSecondaryButton>
    </EmptyFamilyMembersPlaceholderBox>
  )
}

const EmptyFamilyMembersPlaceholderBox = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 20rem);
`

const ContainerImg = styled.img`
  width: 292px;
  height: 186px;
`

const EmptyTitle = styled.h2`
  margin-top: 20px;
  font-weight: 400;
  font-size: 24px;
  line-height: 37px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 280px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    line-height: 32px;
    max-width: unset;
    font-weight: 500;
  }
`

const EmptyText = styled.p`
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: none;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  max-width: 520px;

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    display: flex;
  }
`

const StyledSecondaryButton = styled.button`
  margin-top: 32px;
  background: transparent;
  border: 1px solid var(--button-secondary-default-border);
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--text-primary);
  padding: 8px 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--button-secondary-hover)
  }

  @media (min-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    margin-top: 40px;
  }
`
