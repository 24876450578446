import { USER_DATA_KEY_IN_STORAGE } from 'features/user/constants'
import queryString from 'query-string'

import { getCurrentAppConfigApiHost } from "../features/common/configs/additionalConfigService"

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "HEAD"

export type Options = {
  headers?: {
    [key: string]: string
  },
  host?: string,
  apiVersion?: number,
  isUploadLog?: boolean,
  signal?: AbortSignal
}

type Data = {
  [key: string]: any
}

const getDefaultHeaders = (options?: Options) => {
  return !options?.isUploadLog && {
    Accept: 'application/json',
    "Mountbit-User-Agent": "web-client"
  }
}

export const request = async (method: Method, url: string, data?: Data, options?: Options) => {
  const uri = `${options?.host === null ? '' : options?.host || getCurrentAppConfigApiHost()}${createUrl(method, url, data)}`
  const headers = new Headers({
    ...getDefaultHeaders(options),
    ...createContentType(method, options),
    ...createTokenHeader(getToken(), options),
    ...options?.headers
  })

  const config = new Request(uri, {
    method,
    headers,
    body: createBody(method, data),
    signal: options?.signal,
  })

  const response = await fetch(config)

  const responseHeaders = {}

  response.headers.forEach((value, key) => {
    responseHeaders[key] = value
  })

  const status = response.status
  let responseData = await (async () => {
    let parsedJson = {}

    try {
      parsedJson = await response.json()

      return parsedJson
    } catch (error) {
      return parsedJson
    }
  })() as any

  if (status < 200 || status >= 400) {
    throw { ...responseData, data: responseData,  }
  }

  if (!responseData) {
    responseData = {}
  }

  responseData.headers = { ...responseHeaders }

  return responseData
}

const createContentType = (method: Method, options: Options): {"Content-Type": string} | null => {
  if (method !== 'GET' && !options?.isUploadLog) {
    return { "Content-Type": 'application/json' }
  }

  return null
}

const createTokenHeader = (token: string | null, options?:Options): ({"Mountbit-Auth": string} | null) => {
  return token && !options?.isUploadLog ? { "Mountbit-Auth": token } : null
}

const getToken = () => localStorage.getItem(USER_DATA_KEY_IN_STORAGE) && JSON.parse(`${localStorage.getItem(USER_DATA_KEY_IN_STORAGE)}`).CurrentUser.token

const createBody = (method: Method, data?: Data | FormData): FormData | string | File | undefined => {
  if (data instanceof File || data instanceof FormData) {
    return data
  }

  if (method !== 'GET' && method !== 'HEAD' && data) {
    return JSON.stringify(data)
  }

  return undefined
}

const createUrl = (method: Method, url: string, data?: Data) => {
  if (method === 'GET' && data && !!Object.keys(data).length) {
    return `${url}?${queryString.stringify(data)}`
  }

  return url
}
