import { getModalsRootElement } from 'constants/modals'
import { LOADING_STATUSES } from 'constants/loadingStatuses'
import { formatImgUrl } from 'constants/configs/theme.config'

import React, { useEffect } from 'react'

import {
  CopyMoveModal as CopyMoveModalFromUiKit,
  FilesTableBreadcrumbs,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton, SpriteIcon,
  STYLED_VARIABLES,
} from '@cloudike/web_ui_components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from  "store"

import { validationSchemaForFileItem } from '../validationSchemaForFileItem'
import { useResizeWindow } from "../../common/hooks/useResizeWindow"
import { THEME } from "../../user/userSlice"
import {getUiTheme, getUserDataSelector} from "../../user/selectors"

import {
  fetchFolderSelectionNodesThunk, folderSelectionModalActions,
} from './folderSelectionModalSlice'
import {
  getFolderSelectionModalBreadcrumbsSelector, getFolderSelectionModalNodesSelector,
  getFolderSelectionModalStatusSelector
} from './selectors'
import {filesApi} from "../../../api/filesApi";

enum FooterButtonsTypes {
 SECONDARY = 'SECONDARY',
 PRIMARY = 'PRIMARY',
}

interface Props {
  onClose: () => void,
  onSelect: (params: { name: string, id: string }) => void
}

export const FolderSelectionModal: React.FC<Props> = ({
  onClose, onSelect
}) => {
  const dispatch = useAppDispatch()

  const nodes = getFolderSelectionModalNodesSelector()
  const breadcrumbs = getFolderSelectionModalBreadcrumbsSelector()
  const status = getFolderSelectionModalStatusSelector()
  const currentFolder = breadcrumbs[breadcrumbs.length - 1]?.id || ''
  const widthWindow = useResizeWindow()

  const { t } = useTranslation()

  const theme = getUiTheme()

  useEffect(() => {
    dispatch(fetchFolderSelectionNodesThunk({ parentId: currentFolder }))
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [currentFolder])


  const handleClose = () => {
    onClose()
    dispatch(folderSelectionModalActions.resetState())
  }

  const handleOpenFolder = (folder) => {
    const { name, id } = folder

    dispatch(folderSelectionModalActions.addBreadcrumb({ name, id }))
  }

  const handleBreadcrumbClick = (breadcrumb) => {
    dispatch(folderSelectionModalActions.goToBreadcrumb(breadcrumb))
  }

  const handleActivate = () => {
    onSelect(breadcrumbs[breadcrumbs.length - 1])

    handleClose()
  }

  const buttons = [
    {
      type: FooterButtonsTypes.SECONDARY,
      className: 'modal-dialog__cancel',
      actionName: t('a_common_cancel'),
      onAction: handleClose,
    },
    {
      type: FooterButtonsTypes.PRIMARY,
      className: 'modal-dialog__button_action',
      actionName: t('a_common_select'),
      onAction: handleActivate,
    },
  ]

  const buttonList = buttons.map((props) => {
    switch (props.type) {
    case FooterButtonsTypes.PRIMARY:
      return (
        <PrimaryButton
          key={props.actionName}
          actionName={props.actionName}
          onAction={props.onAction}
        />
      )
    case FooterButtonsTypes.SECONDARY:
      return (
        <SecondaryButtonBox
          key={props.actionName}
          actionName={props.actionName}
          onAction={props.onAction}
        />
      )
    }
  })

  const title = t('l_common_selectFolder')

  return (
    <ModalDialog
      isOpened={true}
      parentBlock={getModalsRootElement()}
      header={(
        <SHeaderBox>
          <StyledModalHeader title={title} />

          <SHeaderCloseButton iconName="close"
            onClick={onClose}
          />
        </SHeaderBox>

      )}
      footer={(
        <ModalFooter
          buttonList={buttonList}
        />
      )}
      onCloseModal={handleClose}
    >
      <CopyMoveModalFromUiKit
        theme={theme}
        isLoading={status === LOADING_STATUSES.LOADING}
        isNewItemVisible={false}
        validateSchema={validationSchemaForFileItem}
        breadcrumbs={
          breadcrumbs.length > 0 ? (
            <FilesTableBreadcrumbs
              widthWindow={widthWindow}
              firstItem={t('l_common_allFiles')}
              filePath={breadcrumbs}
              onClickedPath={handleBreadcrumbClick}
            />
          ) : null
        }
        newFolderDefaultName={t('l_files_createFolderPlaceholder')}
        filesList={nodes as any}
        activeElements={[]}
        onCreateFolder={() => undefined}
        onCancelFolderCreation={() => undefined}
        onGoToFolder={handleOpenFolder}
        emptyFolderContent={status !== LOADING_STATUSES.LOADING && (
          <EmptyFolderBox>
            {theme === THEME.LIGHT ?
              <EmptyFolderImg src={formatImgUrl("/assets/placeholders-images/no-albums.svg")} /> :
              <EmptyFolderImg src={formatImgUrl("/assets/placeholders-images-black/no-albums.svg")} />}
          </EmptyFolderBox>
        )}
      />
    </ModalDialog>
  )
}

const SecondaryButtonBox = styled(SecondaryButton)`
  margin-right: 8px;
`

const EmptyFolderBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const EmptyFolderImg = styled.img`
  width: 186px;
  height: auto;
`

const StyledModalHeader = styled(ModalHeader)`
  .modal-header-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 600px;
  }

  .modal-header-text {
    word-wrap: unset;
  }

  @media (max-width: ${STYLED_VARIABLES.BREAKPOINTS.PHONE_100}) {
    .modal-header-title {
      max-width: 320px;
    }

    .modal-header-text {
      word-wrap: break-word;
    }
  }
`

const SHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const SHeaderCloseButton = styled(SpriteIcon)`
  color: var(--icon-primary);
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
`
