import { getModalsRootElement } from 'constants/modals'

import * as React from 'react'

import { ConfirmationModal, ConfirmationModalTypes } from '@cloudike/web_ui_components'

interface DialogModalProps {
  title?: string,
  icon?: string,
  children?: React.ReactElement,
  header?: React.ReactElement,
  okText?: string,
  cancelText?: string,
  onOk?: () => void,
  onCancel?: () => void,
  isOpened,
  onClose,
  withLongButtons?: boolean,
  type?: ConfirmationModalTypes
  className?: string
}

export const DialogModal: React.FC<DialogModalProps> = ({ title, icon, children, header, okText, onOk, cancelText, onCancel, isOpened, onClose, withLongButtons = false, type }) => {
  if (!isOpened) {
    return null
  }

  return (
    <ConfirmationModal parentBlock={getModalsRootElement()}
      title={title}
      icon={icon}
      header={header}
      okText={okText}
      onOk={onOk}
      cancelText={cancelText}
      onCancel={onCancel}
      isOpened={isOpened}
      onClose={onClose}
      withLongButtons={withLongButtons}
      type={type}
    >
      {children}
    </ConfirmationModal>
  )
}
